import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages: {[key: string]: any} = {
  th: {
    play: 'เข้าเล่น',
    deposit: 'ฝากเงิน',
    withdraw: 'ถอนเงิน',
    withdraw_amount: 'จำนวนเงินที่ต้องการถอน',
    withdraw_min: '- ถอนขั้นต่ำ 50. /ครั้ง',
    withdraw_bank_close: '- ระบบธนาคารปิดปรับปรุง โปรดเลี่ยงการแจ้งถอนในช่วงเวลา <br>23:50-00:10น.',
    withdraw_to_bank: 'เข้าบัญชีนี้',
    deposit_account: 'กรุณาใช้เลขบัญชีที่สมัครโอนเข้ามาเท่านั้น',
    deposit_promotion: 'หากต้องการรับโปร โปรดกดรับโปรทุกครั้งก่อนโอนเงินนะคะ',
    deposit_promotion2: 'รับโปรโมชั่น',
    deposit_promotion3: 'ไม่รับโปรโมชั่น',
    deposit_slip: 'หากเงินไม่เข้าภายใน 5 นาที อัพโหลดสลิปที่นี่!!',
    close: 'ปิดหน้าต่าง',
    close_announcement: 'รับทราบแล้ว',
    skip_announcement: 'ข้ามไปก่อน',
    contact_service: `<span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
								<label class="-text-message ">ติดปัญหา&nbsp;</label>&nbsp;
								<a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
									<u>ติดต่อฝ่ายบริการลูกค้า</u>
								</a>
							</span>`,
    promotion: 'โปรโมชั่น',
    partner: 'พันธมิตร',
    cashback: 'คืนยอด',
    title: 'ระบบ Casino ที่ดีที่สุด ส่งตรงจาก Casino ชื่อดังในสิงคโปร์',
    description_deposit: 'ฝาก ไม่รับโบนัส, ถอนได้ทันทีไม่มีเงื่อนไข',
    all_brands: 'แบรนด์ทั้งหมดที่ดีที่สุด รวมอยู่ในที่นี่แล้ว',
    description_all_brands: 'ใช้ได้หมดทุกแบรนด์',
    withdraw_all: 'โยกเงินออกทั้งหมด',
    refresh_credit: 'รีเฟรสเครดิตทั้งหมด',
    qty_credit: 'จำนวนเครดิต',
    footer_description: 'คาสิโนชั้นนำ รวมแบรนด์ดัง มีทุกค่าย รับง่าย ถอนไว',
    footer_description2: 'ศูนย์รวมแบรนด์ที่ดีทีสุดและเกมส์คาสิโนทุกเกมส์ไว้ที่นี่ที่เดียว อาทิ UFA IMI AMB EDMBET และ Betflix เฉพาะครอบครัว Shiba888 เท่านั้น',
    promotion_title: 'โปรโมชัน ที่ Shiba888 มีมากกว่า แตกง่ายกว่า',
    link_partner: 'ลิงก์รับทรัพย์',
    deposit_title: 'ยอดฝาก',
    deposit_title2: 'ยอดเสีย',
    deposit_title3: 'ส่ง Link ได้ทุกช่องทางรับทรัพย์กับเรา',
    copy: 'คัดลอก',
    partner_title: 'รายได้ผู้แนะนำ',
    partner_title2: 'แนะนำเพื่อน',
    partner_title3: 'เพื่อนแนะนำเพื่อน',
    partner_title4: 'รายได้แนะนำเพื่อน',
    partner_title5: 'รายได้เพื่อนแนะนำเพื่อน',
    partner_title6: 'รายได้ทั้งหมด',
    partner_title7: 'โอนเงินเข้ากระเป๋าหลัก',
    deposit_type: {
      deposit: 'ยอดฝาก',
      loss: 'ยอดเสีย'
    },
    login_page: {
      mobile: 'เบอร์โทรศัพท์',
      forgot_password: 'ลืมรหัสผ่าน ?',
      contact_admin: 'ติดต่อแอดมิน',
      password: 'รหัสผ่าน',
      login_with: 'เข้าสู่ระบบ',
      login_with_line: 'เข้าสู่ระบบด้วยไลน์',
      login_with_facebook: 'เข้าสู่ระบบด้วยเฟสบุ๊ค',
      login_with_google: 'เข้าสู่ระบบด้วย Google',
      no_account: 'หรือยังไม่มีบัญชี',
      register: 'สมัครสมาชิก',
      change_password: 'เปลี่ยนรหัสผ่าน',
      confirm_mobile_account: 'กรุณากรอกเบอร์โทรศัพท์และเลขที่บัญชี',
      account_number: 'เลขที่บัญชี',
      new_password: 'กรุณากรอกรหัสผ่านใหม่',
      new_password2: 'รหัสผ่านใหม่',
      confirm_new_password: 'ยืนยันรหัสผ่านใหม่',
      confirm: 'ยืนยัน',
      please_check_password: 'โปรดตรวจสอบข้อมูลรหัสให้ถูกต้องค่ะ',
      text_header: 'ข้อความ!',
      change_password_success: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ',
      please_fill_data: 'โปรดระบุข้อมูลให้ครบด้วยค่ะ!!!',
      please_contact_admin: 'โปรดติดต่อแอดมิน @shiba888vip',
      mobile_password_error: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!',
    },
    register_page: {
      title: 'สมัครสมาชิก',
      register_success: 'การสมัครของคุณสำเร็จแล้ว',
      please_fill_data: 'กรุณากรอกข้อมูลของท่าน',
      please_fill_password: 'กรุณากรอกรหัสผ่านของท่าน',
      confirm_password: 'ยืนยันรหัสผ่าน',
      next: 'ต่อไป',
      back: 'ย้อนกลับ',
      please_select_bank: 'กรุณาเลือกธนาคารของท่าน',
      select_bank: 'เลือกธนาคาร',
      account_number: 'เลขที่บัญชี',
      firstname: 'ชื่อ',
      lastname: 'นามสกุล',
      register: 'สมัครสมาชิก',
      thank_for_register: 'ขอบคุณสำหรับการสมัคร คุณสามารถใช้ชื่อเข้าระบบ และ รหัสผ่าน ด้่านล่างที่ตั้งไว้เพื่อเข้าสู่ระบบ',
      username: 'ชื่อเข้าระบบ',
      password: 'รหัสผ่าน',
      login: 'เข้าสู่ระบบ',
      or: 'หรือ',
      have_account: 'มีบัญชีอยู่แล้ว',
      contact_admin: 'ติดต่อแอดมิน',
      fill_account_no: 'กรุณากรอกหมายเลขบัญชีค่ะ!!!',
      fill_name: 'กรุณากรอกชื่อค่ะ!!!',
      fill_lastname: 'กรุณากรอกนามสกุลค่ะ!!!',
      please_contact_admin: 'โปรดติดต่อแอดมินที่ @shiba888vip เพื่อแจ้งปัญหาในการสมัคร',
      name_exist: 'ขื่อนามสกุลเป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @shiba888vip',
      check_password_length: 'กรุณากรอกรหัสผ่านอย่างน้อย 6 หลักค่ะ',
      check_password_not_match: 'รหัสผ่านไม่ตรงกันค่ะ!!!',
      fill_mobile: 'กรุณากรอกเบอร์โทรศัพท์ค่ะ!!!',
      check_mobile_length: 'กรุณากรอกเบอร์โทรศัพท์อย่างน้อย 10 หลักค่ะ',
      mobile_exist: 'เบอร์โทรศัพท์นี้มีอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @shiba888vip',
      account_no_exist: 'หมายเลขบัญชีนี้เป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @shiba888',
    },
    home_page: {
      firstpage: 'หน้าแรก',
      contactus: 'ติดต่อเรา',
      firsttitle: 'ยินดีต้อนรับสู่ Shiba888 โลกแห่งการเดิมพันที่ดีที่สุด',
      checkin_error: 'ยอดฝากรวมต่อวันครบ 100 Check-In ฟรีจ้า..!!!',
      checkin_success: 'ท่าน Check-in เรียบร้อยแล้วค่ะ',
      checkin_error2: 'ท่าน Check-in วันนี้แล้วนะคะรอพรุ่งนี้นะคะ!!!',
      account: 'ข้อมูลบัญชี',
      logout: 'ออกจากระบบ',
      change_password: 'เปลี่ยนรหัสผ่าน',
      contact: `<label class="-text-message ">พบปัญหา</label>
									<a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
										<u>ติดต่อฝ่ายบริการลูกค้า</u>
									</a>`,
      password: 'รหัสผ่านปัจจุบัน',
      new_password: 'รหัสผ่านใหม่',
      new_password2: 'ยืนยันรหัสผ่านใหม่',
      confirm: 'ยืนยัน',
      line_login: 'เข้าสู่ระบบด้วยไลน์',
      facebook_login: 'เข้าสู่ระบบด้วย Facebook',
      google_login: 'เข้าสู่ระบบด้วย Google',
      want_withdraw: 'แจ้งถอน',
      turndone: `คุณทำเทิร์นครบแล้ว! จะถอนได้ {canwithdraw} บาท<br> กดปุ่มยืนยันการถอนได้เลยค่ะ`,
      turnfail: `คุณต้องทำยอด {turncredit} ค่ะ`,
      slip_success: 'แจ้งสลิปเรียบร้อย',
      slip_sorry: 'ทีมงานขออภัยในความล่าช้าและจะรีบดำเนินการให้โดยเร็วที่สุด',
      logout_confirm: 'ยืนยันออกจากระบบ',
      withdraw_confirm: 'ทำรายการถอน',
      withdraw_sorry: 'ขออภัยท่านทำรายการถอนครบ 3 ครั้ง ทำรายการได้อีกครั้งหลังเที่ยงคืนนะคะ',
      withdraw_max: 'ไม่สามารถดำเนินการถอนได้ ท่านสามารถทำการถอนได้สูงสุด 450,000 บาท/วัน รบกวนทำการแจ้งถอนใหม่นะคะ',
      withdraw_success: 'เรียบร้อยแล้วกรุณารอเงินเข้าบัญชี',
      withdraw_truewallet: 'การถอน Truewallet จะต้องมียอดฝากขั้นต่ำ 100 บาท กดถอนได้ทันที',
      withdraw_truewallet2: '1. ต้องมีจำนวนมากกว่า 100 <br>2. ลงท้ายด้วย 0 เช่น 110 220 เป็นต้น',
      withdraw_waiting: 'โปรดรออนุมัติรายการถอนก่อนหน้านี้',
      withdraw_credit: 'โปรดตรวจสอบจำนวนเครดิต',
      withdraw_credit2: 'โปรดตรวจสอบจำนวนเครดิต (ถอนขั้นต่ำ 50)',
      password_length: 'โปรดระบุรหัสผ่านมากกว่า 6 หลักค่ะ',
      password_confirm: 'โปรดตรวจสอบรหัสผ่านใหม่อีกครั้งค่ะ',
      password_success: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ',
      password_old: 'โปรดตรวจสอบรหัสผ่านเดิมค่ะ',
    },
    partner_page: {
      share_link_title: 'ลิ้งค์ช่วยแชร์รับ <span style="color:#FAB537">{bonus}%</span> ฟรี ทุก{type}',
      share_more: 'ยิ่งแชร์มากยิ่งได้มาก',
      copy_to_earn: 'แค่ก๊อปปี้ลิ้งค์ไปแชร์ก็ได้เงินแล้ว',
      share_description: 'ท่านสามารถนำลิ้งค์ด้านล่างนี้หรือนำป้ายแบนเนอร์ ไปแชร์ในช่องทางต่างๆ ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network อื่นๆ หากมีการสมัครสมาชิกโดยคลิกผ่านลิ้งค์ของท่านเข้ามา ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายใต้เครือข่ายของท่านทันที และหากลูกค้าภายใต้เครือข่ายของท่านมีการเดิมพันเข้ามา ทุกยอดการเดิมพัน ท่านจะได้รับส่วนแบ่งในการแนะนำ {bonus}% ทันทีโดยไม่มีเงื่อนไข',
      daily_earn: 'รับได้ทุกๆวัน สะสมไม่มีวันหาย',
      calculation_example: 'ระบบจะทำการคำนวณยอด เช่น แนะนำสมาชิกได้และสมาชิกมี{type}รวมทั้งหมด 200,000 x {bonus}% = {result} บาท/วัน เป็นรายได้แบบถาวร',
      lifetime_commission: 'ยิ่งแนะนำเยอะ ยิ่งได้เยอะ โยกเงินเข้ากระเป๋าหลักได้ทันที เพียงแค่ชวนมาอยู่ภายใต้ท่านท่านกินค่าคอมมิชชั่นฟรีไปตลอดชีวิต',
      monthly_potential: 'สามารถทำรายได้เดือน 100,000 บาทง่ายๆเลยทีเดียว และรายได้ทุกบาททุกสตางค์ของท่านสามารถตรวจสอบได้ทุกขั้นตอน แจกจริง จ่ายจริง ที่นี่ที่เดียวที่ให้คุณมากกว่าใคร ก๊อปปี้ลิ้งค์นำไปแชร์ได้เลย'
    },
    cashback_page: {
      title: 'คืนยอดเล่น',
      title2: 'ยอดเล่น',
      description: `<p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;color:#000;">
                <b class="tt_l tt_full fr_tx1" style="font-size:20px;">คืนยอดรับได้<font color="#FAB537">ทุกวันเวลา 09.00 น.</font> เล่นเสียคืนให้ <font color="#FAB537">5% ว้าวๆ...</font></b>
            </p>
            <p style="text-align: center;padding:0px;font-size: 14px;font-family: Kanit;color:#000;">
                <b class="tt_l tt_full fr_tx1">** ถอนได้ทันทีหรือเล่นต่อก็ได้ไม่มีเงื่อนไข **</b>
            </p>`,
    },
    history_page: {
      title: 'ประวัติการคืนยอด',
      title2: 'วัน/เวลา',
      title3: 'ประเภท',
      title4: 'จำนวน',
      title5: 'สถานะ',
      no_data: 'ยังไม่มีรายการ',
    },
    continue_page: {
      title: 'ฝากต่อเนื่อง',
      title2: 'ฝากต่อเนื่อง 7 วัน',
      title3: 'โบนัส',
      title4: 'ฝากต่อเนื่อง รับได้ทุกวันเวลา 7.00 น.',
    },
    boxs_page:{
      title:'หีบมหาสมบัติ',
      title2:'**เงื่อนไขยอดเสียครบ 250 บาทต่อวัน ได้รับสิทธิ์ทันที**',
      message:'💎 ลูกค้าเปิดหีบสมบัติไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
      message2:'ยอดเสียเมื่อวานขาดอีก {less}(250) ค่ะ!😘😘',
    },
    spin_page:{
      title:'วงล้อรับเครดิตฟรี',
      title2:'**เงื่อนไขฝากครบ 500 บาทต่อวัน ได้รับสิทธิ์ทันที**',
      message:'🎡 ลูกค้าหมุนวงล้อของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
      message2:'เติมเงินอีก {less}(500) ได้เล่นเลยค่ะ!😘😘',
      success:'🎡 ปังปุรีเย่!! คุณได้รับ {winner} 😘',
      credit:'เครดิต',
      point:'คะแนน',
    },
    roulet_page: {
      title:'รูเล็ตรับเครดิตฟรี',
      title2:'**เงื่อนไขฝากครบ 1000 บาทต่อวัน ได้รับสิทธิ์ทันที**',
      message:'🏟 ลูกค้าหมุนรูเล็ตของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
      message2:'เติมเงินอีก {less}(1000) ได้เล่นเลยค่ะ!😘😘',
      fail:'🏟 TT เศร้าสุดๆ 😘😘',
      success:'🏟 ปังปุรีเย่!! คุณได้รับ {winner} เครดิต😘😘',
      spin:'หมุนได้เลย',
    },
    gashapon_page: {
      title: 'กาชาปอง',
      title2: '**เงื่อนไขยอดเสียครบ 500 บาทต่อวัน ได้รับสิทธิ์ทันที**',
      message: '💎 ลูกค้าหมุนกาชาปองของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
      message2: 'ยอดเสียเมื่อวานขาดอีก {less}(500) ค่ะ!😘😘',
      success: '💎 ปังปุรีเย่!! คุณได้รับ {winner} เหรียญ😘😘',
    },
    paoyingchup_page: {
      title: 'เป่ายิ้งฉุบ',
      title2: '**เงื่อนไขยอดเสียครบ 1000 บาทต่อวัน ได้รับสิทธิ์ทันที**',
      message: '✌️ ลูกค้าเล่นเป่ายิ้งฉุบของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
      message2: 'ยอดเสียเมื่อวานขาดอีก {less}(1000) ค่ะ!😘😘',
      fail1: 'คุณเสมอเสียใจด้วยนะจ๊ะ 😢',
      fail2: 'คุณแพ้เสียใจด้วยนะจ๊ะ 😢',
      success: '✌️ ปังปุรีเย่!! คุณได้รับ {item} เหรียญ😘😘',
    },
    huekoy_page: {
      title: 'หัวก้อย',
      title2: '**เงื่อนไขมียอดเสีย 2 วันติดต่อกันและมียอดเสียรวมมากกว่า 300 ขึ้นไปได้รับสิทธิ์ทันที**',
      success: '💎 ปังปุรีเย่!! คุณได้รับ 40 เหรียญ😘😘',
      fail: 'เสียใจด้วยนะจ๊ะ 😢',
      message: '💎 ลูกค้าเล่นหัวก้อยของเราไปแล้วนะคะ รอวันใหม่นะคะ 😘',
      message2: '💎 ลูกค้าไม่ได้มียอดเสียสองวันติดนะคะ 😘',
      message3: '💎 ลูกค้าต้องมียอดเสียสองวันมากกว่า {checkcashback} นะคะ 😘',
      message4: '💎 ยอดเสียขาดอีก {less}(300) ค่ะ!😘😘',
      message5: 'พร้อมเล่นได้เลยค่ะ!😘😘'
    },
    huay_page: {
      title: 'แทงหวยไม่ยั้ง',
      title2: '**เงื่อนไขฝากทุกๆ 200 ได้รับสิทธิ์ทันที เฉพาะวันที่หวยออก**',
      description: '**ทุกๆยอดฝาก 200 บาท รับทันที 1 สิทธิ์แทงเลขท้าย 2 ตัวล่าง รัฐบาล แทงถูก!!! รับฟรี 500 เครดิต หากตรวจพบการโกงหรือทุจริตจะถูกตัดสิทธิ์ทันที**',
      confirm: 'ยืนยันแทงหวย',
      success: 'แทงหวยเรียบร้อยแล้วค่ะ 😘',
      warning: 'โปรดตรวจสอบสิทธิ์จำนวนที่แทง',
    },
    rewards_page: {
      title: 'ของรางวัลมีให้แลกมากมาย ที่ Shiba888',
      description: 'มีเหรียญแลกรับเครดิตได้ทันที',
      detail: 'ทำยอด {turn} เท่า ถอนได้สูงสุด {maxwithdraw} เท่าเลยจ้า..',
      confirm: 'ยืนยัน',
      cancel: 'ยกเลิก',
      warning: 'ไม่สามารถแลกเครดิตได้เนื่องจากมียอดคงเหลือมากกว่า 5 บาท',
      warning2: 'ท่านมีมากกว่า 5 เครดิต ไม่สามารถเล่นได้!!!',
      title2: 'แลกเครดิต'
    },
    images: {
      title: require('@/assets/images/language/th/title.png'),
      play: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fplay%2FLoading_Thai.png?alt=media&token=1272bdde-4918-4e8b-a95d-168033bf9908',
      menu: {
        promotion: require('@/assets/images/language/th/07.png'),
        history: require('@/assets/images/language/th/05.png'),
        withdraw: require('@/assets/images/language/th/04.png'),
        play: require('@/assets/images/language/th/13.png'),
        deposit: require('@/assets/images/language/th/03.png'),
        paoyingchup: require('@/assets/images/language/th/01.png'),
        gashapon: require('@/assets/images/language/th/16.png'),
        checkin: require('@/assets/images/language/th/02.png'),
        spin: require('@/assets/images/language/th/09.png'),
        roulet: require('@/assets/images/language/th/10.png'),
        boxs: require('@/assets/images/language/th/17.png'),
        huekoy: require('@/assets/images/language/th/14.png'),
        huay: require('@/assets/images/language/th/15.png'),
        continue: require('@/assets/images/language/th/12.png'),
        partner: require('@/assets/images/language/th/06.png'),
        cashback: require('@/assets/images/language/th/08.png'),
        rewards: require('@/assets/images/language/th/11.png'),
      },
      banners: {
        banner1: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F01.png?alt=media',
        banner2: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F02.png?alt=media',
        banner3: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F03.png?alt=media',
        banner4: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F04.png?alt=media',
        banner5: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F05.png?alt=media',
        banner6: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F06.png?alt=media',
        banner7: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F07.png?alt=media',
        banner8: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F08.png?alt=media',
        banner9: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F09.png?alt=media',
        banner10: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F10.png?alt=media',
        banner11: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F11.png?alt=media',
        banner12: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F12.png?alt=media',
        banner13: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F13.png?alt=media',
        banner14: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F14.png?alt=media',
        banner15: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fth%2F15.png?alt=media',
      }
    }
  },
  en: {
    play: 'Play',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    withdraw_amount: 'Withdrawal Amount',
    withdraw_min: '- Minimum withdrawal: 50 THB per transaction.',
    withdraw_bank_close: '- Bank system maintenance in progress. Please avoid making withdrawal requests during <br>23:50-00:10.',
    withdraw_to_bank: 'Withdraw to this account',
    deposit_account: 'Please use the registered account number for deposits only.',
    deposit_promotion: 'If you want to receive a promotion, please claim it every time before making a deposit.',
    deposit_promotion2: 'Receive Promotion',
    deposit_promotion3: 'Do Not Receive Promotion',
    deposit_slip: 'If the funds are not credited within 5 minutes, upload the slip here!',
    close: 'Close Window',
    close_announcement: 'Acknowledged',
    skip_announcement: 'Skip for Now',
    contact_service: `<span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
                    <label class="-text-message ">Having an issue&nbsp;</label>&nbsp;
                    <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                      <u>Contact Customer Service</u>
                    </a>
                  </span>`,
    promotion: 'Promotion',
    partner: 'Partner',
    cashback: 'Cashback',
    title: 'The Best Casino System, Directly from Renowned Casinos in Singapore',
    description_deposit: 'Deposit, no bonus, withdraw instantly without conditions',
    description_all_brands: 'Use all brands',
    all_brands: 'All the best brands are here',
    withdraw_all: 'Move credit to wallet',
    refresh_credit: 'Refresh all credit',
    qty_credit: 'Qty credit',
    footer_description: 'Leading Casino, featuring top brands, all providers available, easy to claim, fast withdrawals.',
    footer_description2: 'The best brands and casino games are here, including UFA IMI AMB EDMBET and Betflix, only available to Shiba888 family.',
    promotion_title: 'Promotions at Shiba888 are more plentiful and easier to win.',
    link_partner: 'Wealth Earning Link',
    deposit_title: 'Deposit Amount',
    deposit_title2: 'Loss Amount',
    deposit_title3: 'Share the link through any channel to earn with us.',
    partner_title: 'Referral Income',
    partner_title2: 'Refer Friends',
    partner_title3: 'Friends Referring Friends',
    partner_title4: 'Friend Referral Income',
    partner_title5: 'Friend-of-Friend Referral Income',
    partner_title6: 'Total Income',
    partner_title7: 'Transfer to Main Wallet',
    copy: 'Copy',
    deposit_type: {
      deposit: 'Deposit Amount',
      loss: 'Loss Amount'
    },
    login_page: {
      mobile: 'Mobile Number',
      forgot_password: 'Forgot Password?',
      contact_admin: 'Contact Admin',
      password: 'Password',
      login_with: 'Log In',
      login_with_line: 'Log In with LINE',
      login_with_facebook: 'Log In with Facebook',
      login_with_google: 'Log In with Google',
      no_account: 'Don’t have an account yet?',
      register: 'Register',
      change_password: 'Change Password',
      confirm_mobile_account: 'Please enter your mobile number and account number.',
      account_number: 'Account Number',
      new_password: 'Please enter your new password.',
      new_password2: 'New Password',
      confirm_new_password: 'Confirm New Password',
      confirm: 'Confirm',
      please_check_password: 'Please ensure the password information is correct.',
      text_header: 'Message!',
      change_password_success: 'Password changed successfully.',
      please_fill_data: 'Please fill out all the information!',
      please_contact_admin: 'Please contact admin @shiba888vip',
      mobile_password_error: 'Mobile number or password is incorrect!',
    },
    register_page: {
      title: 'Register',
      register_success: 'Your registration has been successful.',
      please_fill_data: 'Please fill in your details.',
      please_fill_password: 'Please enter your password.',
      confirm_password: 'Confirm Password',
      next: 'Next',
      back: 'Back',
      please_select_bank: 'Please select your bank.',
      select_bank: 'Select Bank',
      account_number: 'Account Number',
      firstname: 'First Name',
      lastname: 'Last Name',
      register: 'Register',
      thank_for_register: 'Thank you for registering. You can use the username and password below to log in.',
      username: 'Username',
      password: 'Password',
      login: 'Log In',
      or: 'or',
      have_account: 'Already have an account?',
      contact_admin: 'Contact Admin',
      fill_account_no: 'Please enter your account number!',
      fill_name: 'Please enter your first name!',
      fill_lastname: 'Please enter your last name!',
      please_contact_admin: 'Please contact admin @shiba888vip to report registration issues.',
      name_exist: 'This name is already registered. If you encounter issues, please contact @shiba888vip.',
      check_password_length: 'Please enter a password with at least 6 characters.',
      check_password_not_match: 'Passwords do not match!',
      fill_mobile: 'Please enter your mobile number!',
      check_mobile_length: 'Please enter a mobile number with at least 10 digits.',
      mobile_exist: 'This mobile number is already registered. If you encounter issues, please contact @shiba888vip.',
      account_no_exist: 'This account number is already registered. If you encounter any issues, please contact @shiba888.',
    },
    home_page: {
      firstpage: 'Home',
      contactus: 'Contact Us',
      firsttitle: 'Welcome to Shiba888, the Best World of Betting',
      checkin_error: 'Daily total deposits reached 100. Free Check-In available now!',
      checkin_success: 'You have successfully checked in.',
      checkin_error2: 'You have already checked in today. Please come back tomorrow!',
      account: 'Account Information',
      logout: 'Log Out',
      change_password: 'Change Password',
      contact: `<label class="-text-message ">Having an issue?</label>
                      <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                        <u>Contact Customer Service</u>
                      </a>`,
      password: 'Current Password',
      new_password: 'New Password',
      new_password2: 'Confirm New Password',
      confirm: 'Confirm',
      line_login: 'Log in with LINE',
      facebook_login: 'Log in with Facebook',
      google_login: 'Log in with Google',
      want_withdraw: 'Withdraw Request',
      turndone: `You have completed the required turnover! You can withdraw {canwithdraw} THB.<br> Please press the confirm button to proceed.`,
      turnfail: `You need to complete a turnover of {turncredit}.`,
      slip_success: 'Slip submission completed.',
      slip_sorry: 'We apologize for the delay and will process your request as soon as possible.',
      logout_confirm: 'Confirm Logout',
      withdraw_confirm: 'Confirm Withdrawal',
      withdraw_sorry: 'Sorry, you have reached the maximum of 3 withdrawal requests for today. Please try again after midnight.',
      withdraw_max: 'Unable to process. You can withdraw a maximum of 450,000 THB/day. Please submit a new request.',
      withdraw_success: 'Completed! Please wait for the money to be transferred to your account.',
      withdraw_truewallet: 'For TrueWallet withdrawals, a minimum deposit of 100 THB is required. Click to withdraw instantly.',
      withdraw_truewallet2: '1. The amount must be greater than 100 <br>2. The last digit must end with 0, such as 110 or 220.',
      withdraw_waiting: 'Please wait for the previous withdrawal request to be approved.',
      withdraw_credit: 'Please check the credit amount.',
      withdraw_credit2: 'Please check the credit amount (minimum withdrawal is 50).',
      password_length: 'Please enter a password with more than 6 characters.',
      password_confirm: 'Please recheck your new password.',
      password_success: 'Password successfully changed.',
      password_old: 'Please check your current password.',
    },
    boxs_page: {
      title: 'Treasure Chest',
      title2: '**Condition: Daily total losses of 250 THB to unlock the chest**',
      message: '💎 You have already opened the treasure chest today. Please come back tomorrow 😘',
      message2: 'Your losses yesterday are short by {less} (250 THB)! 😘😘',
    },
    spin_page: {
      title: 'Spin the Wheel for Free Credits',
      title2: '**Condition: Deposit 500 THB daily to unlock the spin**',
      message: '🎡 You have already spun the wheel today. Please come back tomorrow 😘',
      message2: 'Top up another {less}(500) to spin the wheel now! 😘😘',
      success: '🎡 Amazing! You have won {winner} 😘',
      credit: 'Credit',
      point: 'Point',
    },
    roulet_page: {
      title: 'Roulette for Free Credits',
      title2: '**Condition: Deposit 1,000 THB daily to unlock the spin**',
      message: '🏟 You have already spun the roulette today. Please come back tomorrow 😘',
      message2: 'Top up another {less}(1,000) to play now! 😘😘',
      fail: '🏟 TT So sad 😘😘',
      success: '🏟 Amazing! You won {winner} credits 😘😘',
      spin: 'Spin Now',
    },
    gashapon_page: {
      title: 'Gashapon',
      title2: '**Condition: Daily total losses of 500 THB to unlock the spin**',
      message: '💎 You have already spun the gashapon today. Please come back tomorrow 😘',
      message2: 'Your losses yesterday are short by {less}(500) THB! 😘😘',
      success: '💎 Amazing! You won {winner} coins 😘😘',
    },
    paoyingchup_page: {
      title: 'Rock-Paper-Scissors',
      title2: '**Condition: Daily total losses of 1,000 THB to unlock the game**',
      message: '✌️ You have already played Rock-Paper-Scissors today. Please come back tomorrow 😘',
      message2: 'Your losses yesterday are short by {less}(1,000) THB! 😘😘',
      fail1: 'It’s a tie! Better luck next time 😢',
      fail2: 'You lost! Sorry to hear that 😢',
      success: '✌️ Amazing! You won {item} coins 😘😘',
    },
    huekoy_page: {
      title: 'Heads or Tails',
      title2: '**Condition: Have losses for 2 consecutive days with a total loss of more than 300 to unlock the reward**',
      success: '💎 Amazing! You have won 40 coins 😘😘',
      fail: 'Sorry to hear that 😢',
      message: '💎 You have already played Heads or Tails today. Please come back tomorrow 😘',
      message2: '💎 You have not had losses for two consecutive days 😘',
      message3: '💎 You need to have losses for two days totaling more than {checkcashback} 😘',
      message4: '💎 You are short by {less}(300) in losses 😘😘',
      message5: 'Ready to play now! 😘😘',
    },
    huay_page: {
      title: 'Unlimited Lottery Betting',
      title2: '**Condition: Deposit 200 THB for each ticket, only on lottery draw days**',
      description: '**For every deposit of 200 THB, get 1 chance to bet on the last 2 digits of the Thai Government Lottery. Win and receive 500 credits for free! Any fraud or cheating will result in disqualification.**',
      confirm: 'Confirm Lottery Bet',
      success: 'Your lottery bet has been placed successfully 😘',
      warning: 'Please check the number of tickets before placing your bet.',
    },
    rewards_page: {
      title: 'Redeem a Variety of Rewards at Shiba888',
      description: 'Use coins to instantly exchange for credits.',
      detail: 'Make a turnover of {turn} times to withdraw up to {maxwithdraw} times the amount.',
      confirm: 'Confirm',
      cancel: 'Cancel',
      warning: 'Unable to redeem credits as your balance exceeds 5 THB.',
      warning2: 'You have more than 5 credits and cannot proceed!',
      title2: 'Redeem Credits',
    },
    partner_page: {
      share_link_title: 'Share links to earn <span style="color:#FAB537">{bonus}%</span> free on every {type}',
      share_more: 'The more you share, the more you earn',
      copy_to_earn: 'Just copy and share the link to start earning',
      share_description: 'You can share this link or banner through various channels such as personal websites, blogs, Facebook, or other social networks. When someone registers through your link, they will be under your network. For every bet they make, you will receive {bonus}% commission instantly without any conditions.',
      daily_earn: 'Earn daily with no expiration',
      calculation_example: 'System calculates: For example, if your referrals have total {type} of 200,000 x {bonus}% = {result} baht/day as permanent income',
      lifetime_commission: 'The more you refer, the more you earn. Transfer to your main wallet instantly. Just invite them under you to earn commission free for life',
      monthly_potential: 'Easily earn 100,000 baht per month. Every baht of your income can be verified at every step. Real rewards, real payments. Only here gives you more than anyone else. Copy the link and start sharing now'
    },
    cashback_page: {
      title: 'Cashback',
      title2: 'Turnover',
      description: `<p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1" style="font-size:20px;">Get cashback <font color="#FAB537">every day at 09:00 AM.</font> Receive <font color="#FAB537">5% cashback</font> on losses. Wow!</b>
                </p>
                <p style="text-align: center;padding:0px;font-size: 14px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1">** Withdraw instantly or continue playing without conditions **</b>
                </p>`,
    },
    history_page: {
      title: 'Cashback History',
      title2: 'Date/Time',
      title3: 'Type',
      title4: 'Amount',
      title5: 'Status',
      no_data: 'No data',
    },
    continue_page: {
      title: 'Continuous Deposit',
      title2: 'Deposit Continuously for 7 Days',
      title3: 'Bonus',
      title4: 'Deposit continuously and claim every day at 7:00 AM',
    },
    images: {
      title: require('@/assets/images/language/en/title.png'),
      play: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fplay%2FLoading_Eng.png?alt=media&token=1573da13-8c32-4eee-ab26-32e717bf487e',
      menu: {
        promotion: require('@/assets/images/language/en/07.png'),
        history: require('@/assets/images/language/en/05.png'),
        withdraw: require('@/assets/images/language/en/04.png'),
        play: require('@/assets/images/language/en/13.png'),
        deposit: require('@/assets/images/language/en/03.png'),
        paoyingchup: require('@/assets/images/language/en/01.png'),
        gashapon: require('@/assets/images/language/en/16.png'),
        checkin: require('@/assets/images/language/en/02.png'),
        spin: require('@/assets/images/language/en/09.png'),
        roulet: require('@/assets/images/language/en/10.png'),
        boxs: require('@/assets/images/language/en/17.png'),
        huekoy: require('@/assets/images/language/en/14.png'),
        huay: require('@/assets/images/language/en/15.png'),
        continue: require('@/assets/images/language/en/12.png'),
        partner: require('@/assets/images/language/en/06.png'),
        cashback: require('@/assets/images/language/en/08.png'),
        rewards: require('@/assets/images/language/en/11.png'),
      },
      banners: {
        banner1: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F01.png?alt=media',
        banner2: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F02.png?alt=media',
        banner3: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F03.png?alt=media',
        banner4: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F04.png?alt=media',
        banner5: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F05.png?alt=media',
        banner6: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F06.png?alt=media',
        banner7: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F07.png?alt=media',
        banner8: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F08.png?alt=media',
        banner9: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F09.png?alt=media',
        banner10: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F10.png?alt=media',
        banner11: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F11.png?alt=media',
        banner12: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F12.png?alt=media',
        banner13: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F13.png?alt=media',
        banner14: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F14.png?alt=media',
        banner15: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fen%2F15.png?alt=media',
      }
    }
  },
  //แปลภาษาจากตัวแปล en เป็นภาษา myanmar ให้ตรงกับ field ที่มีอยู่ใน en
  my: {
    play: 'ဆော့ရန်',
    deposit: 'ငွေသွင်းရန်',
    withdraw: 'ငွေထုတ်ရန်',
    withdraw_amount: 'ထုတ်ယူမည့်ငွေပမာဏ',
    withdraw_min: '- အနည်းဆုံးထုတ်ယူမှု: 50 ဘတ်/လှုပ်ရှားမှု။',
    withdraw_bank_close: '- ဘဏ်စနစ်ပြုပြင်နေဆဲ ဖြစ်ပါသဖြင့် 23:50-00:10 အချိန်တွင် ထုတ်ယူမှုတောင်းဆိုမှုများအား ရှောင်ရှားပါ။',
    withdraw_to_bank: 'ဤအကောင့်သို့ထုတ်ယူပါ',
    deposit_account: 'ကျေးဇူးပြု၍ မှတ်ပုံတင်ထားသော အကောင့်နံပါတ်ဖြင့်သာ သိုက်ငွေပြုလုပ်ပါ။',
    deposit_promotion: 'ပရိုမိုးရှင်းကို လက်ခံလိုပါက သိုက်မီ အကြိမ်တိုင်း လက်ခံရန် နှိပ်ပါ။',
    deposit_promotion2: 'ပရိုမိုးရှင်းကို လက်ခံပါ',
    deposit_promotion3: 'ပရိုမိုးရှင်းကို မလက်ခံပါ',
    deposit_slip: 'ငွေ 5 မိနစ်အတွင်း ဝင်မည် မဟုတ်ပါက ဤနေရာတွင် ငွေလွှဲ လက်မှတ်တင်ပါ!',
    close: 'ပိတ်ရန်',
    close_announcement: 'သိရှိခဲ့ပါပြီ',
    skip_announcement: 'ယခုအချိန်အတွက် ပျော်ရွှင်ပါ',
    contact_service: `<span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
                    <label class="-text-message ">ပြဿနာရှိပါသလား&nbsp;</label>&nbsp;
                    <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                      <u>ဖောက်သည် ဝန်ဆောင်မှုကိုဆက်သွယ်ပါ</u>
                    </a>
                  </span>`,
    promotion: 'ပရိုမိုးရှင်း',
    partner: 'မိတ်ဖက်',
    cashback: 'ငွေပြန်အမ်း',
    title: 'အကောင်းဆုံး Casino စနစ်၊ စင်္ကာပူမှ နာမည်ကြီး Casinos မှ တိုက်ရိုက်ပို့ဆောင်သည်',
    description_deposit: 'ငွေသွင်းရန်, ငွေပြန်အမ်းမရပါ, ငွေထုတ်ရန်အများကြီးများအတွက်',
    all_brands: 'အကြီးအကဲများအတွက် အကြီးအကဲများအတွက်',
    description_all_brands: 'အကြီးအကဲများအတွက် အကြီးအကဲများအတွက်',
    withdraw_all: 'ခရက်ဒစ်ကိုပိုက်ဆံအိတ်သို့ရွှေ့ပါ',
    refresh_credit: 'ခရက်ဒစ်အားလုံးကိုအသစ်ပြုလုပ်ပါ',
    qty_credit: 'ခရက်ဒစ်ပမာဏ',
    footer_description: 'ဂေဟစနစ်အဆင့်မြင့်၊ နာမည်ကြီးအမှတ်တံဆိပ်များပါဝင်ပြီး၊ ကုမ္ပဏီအားလုံးအတွက်ရရှိနိုင်သည်၊ ရယူရန်လွယ်ကူပြီး၊ ငွေထုတ်ရန်မြန်ဆန်သည်။',
    footer_description2: 'အကောင်းဆုံးအမှတ်တံဆိပ်များနှင့်ကာစီနိုဂိမ်းများကိုတစ်နေရာတည်းတွင်စုစည်းထားသည်။ UFA, IMI, AMB, EDMBET နှင့် Betflix တို့ကို Shiba888 မိသားစုအတွက်ပဲရရှိနိုင်ပါသည်။',
    promotion_title: 'Shiba888 တွင် ပရိုမိုးရှင်းများပိုမိုအများကြီးရှိပြီး အနိုင်ရရန်ပိုမိုလွယ်ကူသည်။',
    link_partner: 'အောင်မြင်မှုရရှိရန်လင့်ခ်',
    deposit_title: 'သိုက်ငွေပမာဏ',
    deposit_title2: 'ဆုံးရှုံးငွေပမာဏ',
    deposit_title3: 'မည်သည့်လမ်းကြောင်းမှမဆို link ကိုပေးပို့ပြီး ကျွန်ုပ်တို့နှင့်အတူဝင်ငွေရှာပါ။',
    copy: 'ငွေကြေးပြန်ပါ',
    partner_title: 'ညွှန်းခြင်းဝင်ငွေ',
    partner_title2: 'မိတ်ဆွေကိုညွှန်းပါ',
    partner_title3: 'မိတ်ဆွေများညွှန်းခြင်း',
    partner_title4: 'မိတ်ဆွေညွှန်းဝင်ငွေ',
    partner_title5: 'မိတ်ဆွေရဲ့မိတ်ဆွေညွှန်းဝင်ငွေ',
    partner_title6: 'စုစုပေါင်းဝင်ငွေ',
    partner_title7: 'အဓိကပိုက်ဆံအိတ်သို့လွှဲပြောင်းပါ',
    deposit_type: {
      deposit: 'သိုက်ငွေပမာဏ',
      loss: 'ဆုံးရှုံးငွေပမာဏ'
    },
    login_page: {
      mobile: 'ဖုန်းနံပါတ်',
      forgot_password: 'စကားဝှက်မေ့သွားပါသလား?',
      contact_admin: 'အက်မင်နှင့်ဆက်သွယ်ပါ',
      password: 'စကားဝှက်',
      login_with: 'ဝင်ရန်',
      login_with_line: 'LINE ဖြင့် ဝင်ရန်',
      login_with_facebook: 'Facebook ဖြင့် ဝင်ရန်',
      login_with_google: 'Google ဖြင့် ဝင်ရန်',
      no_account: 'အကောင့်မရှိသေးပါလား?',
      register: 'အကောင့်ဖွင့်ရန်',
      change_password: 'စကားဝှက်ပြောင်းရန်',
      confirm_mobile_account: 'ဖုန်းနံပါတ်နှင့် အကောင့်နံပါတ် ရိုက်ထည့်ပါ။',
      account_number: 'အကောင့်နံပါတ်',
      new_password: 'စကားဝှက်အသစ် ရိုက်ထည့်ပါ။',
      new_password2: 'စကားဝှက်အသစ်',
      confirm_new_password: 'စကားဝှက်အသစ်အတည်ပြုပါ',
      confirm: 'အတည်ပြုပါ',
      please_check_password: 'စကားဝှက်အချက်အလက်ကို စစ်ဆေးပါ။',
      text_header: 'စာကို!',
      change_password_success: 'စကားဝှက်ပြောင်းပြီးပါပြီ။',
      please_fill_data: 'အချက်အလက်အားလုံး ရိုက်ထည့်ပါ!',
      please_contact_admin: 'အက်မင် @shiba888vip ဆက်သွယ်ပါ။',
      mobile_password_error: 'ဖုန်းနံပါတ် / စကားဝှက် မမှန်ပါ!',
    },
    register_page: {
      title: 'အကောင့်ဖွင့်ရန်',
      register_success: 'သင်၏အကောင့်ဖွင့်ခြင်း အောင်မြင်ပါပြီ။',
      please_fill_data: 'သင်၏အချက်အလက်ကိုဖြည့်ပါ။',
      please_fill_password: 'သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ။',
      confirm_password: 'စကားဝှက်အတည်ပြုပါ',
      next: 'ဆက်လက်လုပ်ဆောင်ရန်',
      back: 'နောက်သို့',
      please_select_bank: 'ကျေးဇူးပြု၍ သင်၏ဘဏ်ကို ရွေးချယ်ပါ။',
      select_bank: 'ဘဏ်ရွေးချယ်ရန်',
      account_number: 'အကောင့်နံပါတ်',
      firstname: 'နာမည်',
      lastname: 'မျိုးနာမည်',
      register: 'အကောင့်ဖွင့်ရန်',
      thank_for_register: 'အကောင့်ဖွင့်ခြင်းအတွက်ကျေးဇူးတင်ပါသည်။ အောက်တွင်သင်၏နာမည်နှင့်စကားဝှက်ကိုဝင်ရန်သုံးနိုင်သည်။',
      username: 'နာမည်',
      password: 'စကားဝှက်',
      login: 'ဝင်မည်',
      or: 'သို့မဟုတ်',
      have_account: 'အကောင့်ရှိပြီးသားလား?',
      contact_admin: 'အက်မင်နှင့်ဆက်သွယ်ပါ',
      fill_account_no: 'ကျေးဇူးပြု၍ အကောင့်နံပါတ်ထည့်ပါ!',
      fill_name: 'နာမည်ကိုရိုက်ထည့်ပါ!',
      fill_lastname: 'မျိုးနာမည်ကိုရိုက်ထည့်ပါ!',
      please_contact_admin: 'အကောင့်ဖွင့်ခွင့်ရှိမဟုတ်ပါက @shiba888vip နှင့်ဆက်သွယ်ပါ။',
      name_exist: 'ဤနာမည်သည်ရှိပြီးသားဖြစ်သည်။ ပြဿနာရှိပါက @shiba888vip ကိုဆက်သွယ်ပါ။',
      check_password_length: 'အနည်းဆုံး စကားဝှက် 6 လုံးရိုက်ထည့်ပါ။',
      check_password_not_match: 'စကားဝှက်များမကိုက်ညီပါ!',
      fill_mobile: 'ဖုန်းနံပါတ်ထည့်ပါ!',
      check_mobile_length: 'အနည်းဆုံး 10 လုံးပါသော ဖုန်းနံပါတ်ကိုရိုက်ထည့်ပါ။',
      mobile_exist: 'ဤဖုန်းနံပါတ်သည် ရှိပြီးသားဖြစ်သည်။ @shiba888vip ကိုဆက်သွယ်ပါ။',
      account_no_exist: 'ဤအကောင့်နံပါတ်သည် ရှိပြီးသားဖြစ်သည်။ ပြဿနာရှိပါက @shiba888 ကိုဆက်သွယ်ပါ။',
    },
    home_page: {
      firstpage: 'မူလစာမျက်နှာ',
      contactus: 'ဆက်သွယ်ရန်',
      firsttitle: 'Shiba888 ၏ ကောင်းမွန်ဆုံး လောင်းကစားကမ္ဘာသို့ ကြိုဆိုပါသည်',
      checkin_error: 'နေ့စဉ် သိုက်ငွေစုစုပေါင်း 100 ပြည့်သွားပါပြီ။ Check-In အခမဲ့ရရှိနိုင်ပါပြီ!',
      checkin_success: 'သင် Check-in အောင်မြင်ပါပြီ။',
      checkin_error2: 'သင်ယနေ့ Check-in ပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါ!',
      account: 'အကောင့်အချက်အလက်',
      logout: 'ထွက်ရန်',
      change_password: 'စကားဝှက်ပြောင်းရန်',
      contact: `<label class="-text-message ">ပြဿနာတစ်ခုရှိပါသလား?</label>
                      <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                        <u>ဖောက်သည်ဝန်ဆောင်မှုကိုဆက်သွယ်ပါ</u>
                      </a>`,
      password: 'လက်ရှိစကားဝှက်',
      new_password: 'စကားဝှက်အသစ်',
      new_password2: 'စကားဝှက်အသစ်အတည်ပြုပါ',
      confirm: 'အတည်ပြုပါ',
      line_login: 'LINE ဖြင့် ဝင်မည်',
      facebook_login: 'Facebook ဖြင့် ဝင်မည်',
      google_login: 'Google ဖြင့် ဝင်မည်',
      want_withdraw: 'ထုတ်ယူမှုတောင်းဆိုပါ',
      turndone: `သင်လိုအပ်သော turnover ပြီးစီးပါပြီ! သင် {canwithdraw} ဘတ် ထုတ်ယူနိုင်သည်။<br> အတည်ပြု ခလုတ်ကို နှိပ်ပါ။`,
      turnfail: `သင် {turncredit} turnover ပြုလုပ်ရန်လိုအပ်ပါသည်။`,
      slip_success: 'လွှဲပြောင်းမှတ်တမ်းတင်ပြမှုပြီးစီးပါပြီ။',
      slip_sorry: 'ကျွန်ုပ်တို့၏ဖြေရှင်းမှုအချိန်ကြာသည်မှာ တောင်းပန်ပါသည်။',
      logout_confirm: 'စနစ်မှထွက်ရန်အတည်ပြုပါ',
      withdraw_confirm: 'ထုတ်ယူမှုအတည်ပြုပါ',
      withdraw_sorry: 'တောင်းပန်ပါသည်၊ သင်ယနေ့အထုတ်ယူမှု 3 ကြိမ် ပြည့်သွားပါပြီ။ မနက်နက် 12 နာရီနောက်မှထပ်လျှောက်ပါ။',
      withdraw_max: 'ပြုလုပ်၍မရပါ။ သင် 450,000 ဘတ်/နေ့ထိထုတ်ယူနိုင်သည်။',
      withdraw_success: 'ပြီးစီးပါပြီ! ငွေသင့်အကောင့်သို့ လွှဲပြောင်းမှုကို စောင့်ဆိုင်းပါ။',
      withdraw_truewallet: 'TrueWallet တင်အတွက် အနည်းဆုံး 100 ဘတ်လိုအပ်ပါသည်။',
      withdraw_truewallet2: '1. 100 ထက်အနည်းဆုံး <br>2. နောက်ဆုံးနံပါတ် 0 ဖြင့်အဆုံးသတ်ပါ။',
      withdraw_waiting: 'ခွင့်ပြုမှုကို စောင့်ဆိုင်းပါ။',
      withdraw_credit: 'ကျေးဇူးပြု၍ အကြွေများစစ်ဆေးပါ။',
      withdraw_credit2: 'အနည်းဆုံး 50 မှထုတ်ယူမှု စစ်ဆေးပါ။',
      password_length: '6 စာလုံးထက်ပိုသော password ရိုက်ထည့်ပါ။',
      password_confirm: 'အသစ် password ကို စစ်ဆေးပါ။',
      password_success: 'Password ပြောင်းပြီးပါပြီ။',
      password_old: 'စစ်ဆေးမှုအတွက် password ရိုက်ထည့်ပါ။',
    },
    boxs_page: {
      title: 'အမှတ်တရသို့ရောက်ပါ',
      title2: '**အခြေအနေ: နေ့စဉ်ဆုံးရှုံးငွေ 250 ဘတ် ရရှိပါက ခွင့်ပြုပါသည်**',
      message: '💎 သင်ယနေ့ အမွေအနှစ်သေတ္တာဖွင့်ပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: 'မနေ့က သင်၏ဆုံးရှုံးမှုမှာ {less} (250 ဘတ်) လိုနေပါသည်! 😘😘',
    },
    spin_page: {
      title: 'အခမဲ့အကြွေဖရားအတွက် လှည့်စေ့ပွား',
      title2: '**အခြေအနေ: နေ့စဉ် 500 ဘတ် သိုက်ရရှိပါက ခွင့်ပြုပါသည်**',
      message: '🎡 သင်ယနေ့က ငါတို့ရဲ့လှည့်စေ့ပွားကိုလှည့်ပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: 'အခမဲ့လှည့်ဖို့ {less}(500) ထပ်ဖြည့်ပါ! 😘😘',
      success: '🎡 အံ့ဩစရာ! သင် {winner} ရရှိခဲ့ပါပြီ 😘',
      credit: 'ခရက်ဒစ်',
      point: 'အမှတ်',
    },
    roulet_page: {
      title: 'အခမဲ့အကြွေဖရားအတွက် ရူလက်',
      title2: '**အခြေအနေ: နေ့စဉ် 1,000 ဘတ် သိုက်ရရှိပါက ခွင့်ပြုပါသည်**',
      message: '🏟 သင်ယနေ့က ရူလက်ကိုလှည့်ပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: 'အခမဲ့လှည့်ဖို့ {less}(1,000) ထပ်ဖြည့်ပါ! 😘😘',
      fail: '🏟 TT ဝမ်းနည်းဆုံး 😘😘',
      success: '🏟 အံ့ဩစရာ! သင် {winner} ခရက်ဒစ်ရရှိခဲ့ပါပြီ 😘😘',
      spin: 'လှည့်ပါ',
    },
    gashapon_page: {
      title: 'ဂါရှပိုန်',
      title2: '**အခြေအနေ: နေ့စဉ်ဆုံးရှုံးငွေ 500 ဘတ် ရရှိပါက ခွင့်ပြုပါသည်**',
      message: '💎 သင်ယနေ့က ဂါရှပိုန်ကိုလှည့်ပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: 'မနေ့က သင်၏ဆုံးရှုံးမှုမှာ {less}(500) ဘတ် လိုနေပါသည်! 😘😘',
      success: '💎 အံ့ဩစရာ! သင် {winner} အမှတ်ရရှိခဲ့ပါပြီ 😘😘',
    },
    paoyingchup_page: {
      title: 'ကျောက်-စက္ကူ-ကတ်',
      title2: '**အခြေအနေ: နေ့စဉ်ဆုံးရှုံးငွေ 1,000 ဘတ် ရရှိပါက ခွင့်ပြုပါသည်**',
      message: '✌️ သင်ယနေ့ ကျောက်-စက္ကူ-ကတ်ကို ကစားပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: 'မနေ့က သင်၏ဆုံးရှုံးမှုမှာ {less}(1,000) ဘတ် လိုနေပါသည်! 😘😘',
      fail1: 'ရလဒ်တူညီပါသည်! နောက်တစ်ကြိမ်မှာ ကံကောင်းပါစေ 😢',
      fail2: 'သင်ရှုံးပါပြီ! စိတ်မကောင်းပါဘူး 😢',
      success: '✌️ အံ့ဩစရာ! သင် {item} ကွိုင်ရရှိခဲ့ပါပြီ 😘😘',
    },
    huekoy_page: {
      title: 'ခေါင်း ဒေါင်',
      title2: '**အခြေအနေ: 2 ရက်ဆက်တိုက်ဆုံးရှုံးမှုနှင့် စုစုပေါင်းဆုံးရှုံးမှု 300 ကျော်ရရှိပါက ခွင့်ပြုပါသည်**',
      success: '💎 အံ့ဩစရာ! သင် 40 ကွိုင်ရရှိခဲ့ပါပြီ 😘😘',
      fail: 'စိတ်မကောင်းပါဘူး 😢',
      message: '💎 သင်ယနေ့ ခေါင်း ဒေါင်ကို ကစားပြီးပါပြီ။ မနက်ဖြန် ပြန်လာပါနော် 😘',
      message2: '💎 သင် 2 ရက်ဆက်တိုက်ဆုံးရှုံးမှုမရှိပါနော် 😘',
      message3: '💎 သင် 2 ရက်တွင် စုစုပေါင်းဆုံးရှုံးမှု {checkcashback} ထပ်လိုနေပါသည် 😘',
      message4: '💎 သင်၏ဆုံးရှုံးမှုမှာ {less}(300) လိုနေပါသည် 😘😘',
      message5: 'ကစားရန်အဆင်သင့်ဖြစ်ပါပြီ! 😘😘',
    },
    huay_page: {
      title: 'ထီထိုးခြင်းကန့်သတ်မရှိ',
      title2: '**အခြေအနေ: ထီထိုးရက်တွင် 200 ဘတ်စီ သိုက်ရမှသာ ခွင့်ပြုပါသည်**',
      description: '**200 ဘတ် သိုက်မှုတိုင်းအတွက် ထီထိုးခွင့် 1 ခုရရှိပါသည်။ ထီကြိုးစားပြီး နောက်ဆုံး 2 လုံးရမှတ်မှန်ပါက 500 ခရက်ဒစ် အခမဲ့ရရှိမည်။ လိမ်လည်မှုများရုပ်သိမ်းခြင်းခံရမည်။**',
      confirm: 'ထီထိုးမှု အတည်ပြုပါ',
      success: 'သင့်ထီထိုးမှု အောင်မြင်စွာပြီးစီးပါပြီ 😘',
      warning: 'ထီတစ်ခုချင်းစီစစ်ဆေးပါ။',
    },
    rewards_page: {
      title: 'Shiba888 တွင် အမျိုးမျိုးသောဆုများကိုလဲလှယ်ပါ',
      description: 'ကျုန်းများကို ချက်ချင်းအကြွေဖရားအဖြစ်လဲလှယ်နိုင်ပါသည်။',
      detail: 'Turnover {turn} ကြိမ် ပြုလုပ်ပြီး {maxwithdraw} ကြိမ်ထိ ပြန်လည်ထုတ်ယူနိုင်ပါသည်။',
      confirm: 'အတည်ပြုပါ',
      cancel: 'မလုပ်တော့',
      warning: 'ငွေလက်ကျန် 5 ဘတ် ကျော်လျှင် အကြွေဖလဲလှယ်၍မရပါ။',
      warning2: 'သင်တွင် 5 ခရက်ဒစ်ထက်ပိုမိုရှိသည်၊ ဆက်လက်လုပ်ဆောင်၍မရပါ!',
      title2: 'ခရက်ဒစ်လဲလှယ်ပါ',
    },
    partner_page: {
      share_link_title: 'လင့်ခ်များကိုမျှဝေ၍ <span style="color:#FAB537">{bonus}%</span> အခမဲ့ရယူပါ {type} တိုင်းအတွက်',
      share_more: 'မျှဝေသလောက် ဝင်ငွေများတိုးမယ်',
      copy_to_earn: 'လင့်ခ်ကိုကူးယူပြီး မျှဝေလိုက်တာနဲ့ ဝင်ငွေရှာနိုင်တယ်',
      share_description: 'ဒီလင့်ခ်ကို သင့်ပုဂ္ဂိုလ်ရေးဝက်ဘ်ဆိုက်များ၊ ဘလော့များ၊ Facebook သို့မဟုတ် အခြားသော လူမှုကွန်ရက်များမှတဆင့်မျှဝေနိုင်သည်။ သင့်လင့်ခ်မှတဆင့် အဖွဲ့ဝင်အသစ်တစ်ဦး အရင်းအမြစ်လုပ်ဆောင်ပါက သင်၏ကွန်ရက်အောက်တွင်ထည့်သွင်းပါမည်။ သူတို့၏ ရွေးနှိုက်မှုတိုင်းတွင် {bonus}% ကော်မရှင်ကို ချက်ချင်းရရှိမည်။',
      daily_earn: 'မည်သည့်နေ့ရက်မဆို အမြဲတမ်းဝင်ငွေရယူပါ',
      calculation_example: 'စနစ်တွက်ချက်ပုံ: ဥပမာအားဖြင့် သင်၏အကြံပြုထားသောလင့်ခ်မှ {type} 200,000 x {bonus}% = {result} ကျပ်/တစ်နေ့အဖြစ် အမြဲတမ်းဝင်ငွေဖြစ်သည်။',
      lifetime_commission: 'အကြံပြုသူများပိုများသလောက်၊ ဝင်ငွေများရရှိမည်။ အဓိကပိုက်ဆံအိတ်သို့ ချက်ချင်းလွှဲပြောင်းပါ။ သူတို့ကိုတင့်တိုင်းဝင်ငွေရယူနိုင်ပါတယ်။',
      monthly_potential: 'လစဉ် 100,000 ကျပ် အလွယ်တကူရရှိပါမည်။ သင့်ဝင်ငွေများအားလုံးကို အစဉ်အဆက်စစ်ဆေးနိုင်ပါသည်။ အမှန်တကယ်ဆုလာဘ်များ၊ အမှန်တကယ်ငွေပေးချေမှုများ။ ဒါက Shiba888 မှသာလျှင်အခြားထက်ပိုရရှိနိုင်ပါသည်။ လင့်ခ်ကိုကူးယူပြီး မျှဝေလိုက်ပါ။'
    },
    cashback_page: {
      title: 'ပြန်အမ်းငွေ',
      title2: 'တိုးတာ',
      description: `<p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1" style="font-size:20px;">ပြန်အမ်းငွေကို <font color="#FAB537">နေ့တိုင်း နံနက် 09:00</font> တွင်ရယူပါ။ ဆုံးရှုံးမှု <font color="#FAB537">5%</font> ပြန်အမ်းငွေရရှိပါမည်။</b>
                </p>
                <p style="text-align: center;padding:0px;font-size: 14px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1">** ချက်ချင်းထုတ်ယူရန် သို့မဟုတ် ဆက်လက်ကစားနိုင်သည် **</b>
                </p>`,
    },
    history_page: {
      title: 'ပြန်အမ်းငွုတ်မှတ်တမ်း',
      title2: 'ရက်စွဲ/အချိန်',
      title3: 'အမျိုးအစား',
      title4: 'ငွေပမာဏ',
      title5: 'အခြေအနေ',
      no_data: 'မရှိပါ',
    },
    continue_page: {
      title: 'ဆက်တိုက်သိုက်ငွေ',
      title2: 'ဆက်တိုက် 7 ရက် သိုက်ငွေ',
      title3: 'ဆုကြေးငွေ',
      title4: 'ဆက်တိုက်သိုက်ငွေပြီး နေ့တိုင်း မနက် 7:00 နာရီတွင် ရယူပါ',
    },
    images: {
      title: require('@/assets/images/language/my/title.png'),
      play:'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fplay%2FLoading_MM.png?alt=media&token=cd575750-140c-4ae9-946f-d33278cdcc62',
      menu: {
        promotion: require('@/assets/images/language/my/07.png'),
        history: require('@/assets/images/language/my/05.png'),
        withdraw: require('@/assets/images/language/my/04.png'),
        play: require('@/assets/images/language/my/13.png'),
        deposit: require('@/assets/images/language/my/03.png'),
        paoyingchup: require('@/assets/images/language/my/01.png'),
        gashapon: require('@/assets/images/language/my/16.png'),
        checkin: require('@/assets/images/language/my/02.png'),
        spin: require('@/assets/images/language/my/09.png'),
        roulet: require('@/assets/images/language/my/10.png'),
        boxs: require('@/assets/images/language/my/17.png'),
        huekoy: require('@/assets/images/language/my/14.png'),
        huay: require('@/assets/images/language/my/15.png'),
        continue: require('@/assets/images/language/my/12.png'),
        partner: require('@/assets/images/language/my/06.png'),
        cashback: require('@/assets/images/language/my/08.png'),
        rewards: require('@/assets/images/language/my/11.png'),
      },
      banners: {
        banner1: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F01.png?alt=media',
        banner2: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F02.png?alt=media',
        banner3: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F03.png?alt=media',
        banner4: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F04.png?alt=media',
        banner5: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F05.png?alt=media',
        banner6: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F06.png?alt=media',
        banner7: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F07.png?alt=media',
        banner8: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F08.png?alt=media',
        banner9: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F09.png?alt=media',
        banner10: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F10.png?alt=media',
        banner11: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F11.png?alt=media',
        banner12: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F12.png?alt=media',
        banner13: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F13.png?alt=media',
        banner14: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F14.png?alt=media',
        banner15: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Fmy%2F15.png?alt=media',
      }
    }
  },
   //แปลภาษาจากตัวแปล en เป็นภาษา laos ให้ตรงกับ field ที่มีอยู่ใน en
  lo: {
    play: 'ຫຼິ້ນ',
    deposit: 'ຝາກເງິນ',
    withdraw: 'ຖອນເງິນ',
    withdraw_amount: 'ຈຳນວນເງິນທີ່ຕ້ອງການຖອນ',
    withdraw_min: '- ຖອນຂັ້ນຕ່ຳ: 50 ບາດ/ຄັ້ງ.',
    withdraw_bank_close: '- ລະບົບທະນາຄານກຳລັງປັບປຸງ. ກະລຸນາເລີ້ຍການຖອນເງິນໃນເວລາ <br>23:50-00:10.',
    withdraw_to_bank: 'ຖອນເຂົ້າບັນຊີນີ້',
    deposit_account: 'ກະລຸນາໃຊ້ເລກບັນຊີທີ່ລົງທະບຽນເທົ່ານັ້ນສຳລັບການຝາກເງິນ.',
    deposit_promotion: 'ຖ້າທ່ານຕ້ອງການຮັບໂປຣໂມຊັນ, ກະລຸນາກົດຮັບທຸກຄັ້ງກ່ອນຝາກເງິນ.',
    deposit_promotion2: 'ຮັບໂປຣໂມຊັນ',
    deposit_promotion3: 'ບໍ່ຮັບໂປຣໂມຊັນ',
    deposit_slip: 'ຖ້າເງິນບໍ່ເຂົ້າພາຍໃນ 5 ນາທີ, ອັບໂຫຼດສະລິບທີ່ນີ້!',
    close: 'ປິດຫນ້າຕ່າງ',
    close_announcement: 'ຮັບຮູ້ແລ້ວ',
    skip_announcement: 'ຂ້າມໄປກ່ອນ',
    contact_service: `<span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
                  <label class="-text-message ">ມີບັນຫາ&nbsp;</label>&nbsp;
                  <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                    <u>ຕິດຕໍ່ພະນັກງານລູກຄ້າ</u>
                  </a>
                </span>`,
    promotion: 'ໂປຣໂມຊັ່ນ',
    partner: 'ຄູ່ຮ່ວມ',
    cashback: 'ເງິນຄືນ',
    title: 'ລະບົບ Casino ທີ່ດີທີ່ສຸດ ສົ່ງຕົງຈາກ Casino ຊື່ດັງໃນສິງຄໂປ',
    description_deposit: 'ຝາກເງິນ, ບໍ່ມີໂບນັສ, ຖອນເງິນທັນທີທີ່ບໍ່ມີເງື່ອນໄຂ',
    all_brands: 'ທຸກຄາສິໂນທີ່ດີທີ່ສຸດແນບມາທີ່ນີ້ແລ້ວ',
    description_all_brands: 'ທຸກຄາສິໂນທີ່ດີທີ່ສຸດແນບມາທີ່ນີ້ແລ້ວ',
    withdraw_all: 'ເຄຣດິດເຂົ້າກະເປົາເງິນ',
    refresh_credit: 'ຟື້ນຟູເຄຣດິດທັງໝົດ',
    qty_credit: 'ຈຳນວນເຄຣດິດ',
    footer_description: 'ຄາສິໂນຊັ້ນນຳ, ລວມຍີ່ຫໍ້ດັງ, ມີທຸກຄ່າຍ, ຮັບງ່າຍ, ຖອນໄວ.',
    footer_description2: 'ສູນກາງຍີ່ຫໍ້ທີ່ດີທີ່ສຸດແລະເກມຄາສິໂນທຸກເກມຢູ່ທີ່ນີ້ທີ່ດຽວ ອາທິ UFA, IMI, AMB, EDMBET ແລະ Betflix ສຳຫຼັບຄອບຄົວ Shiba888 ເທົ່ານັ້ນ.',
    promotion_title: 'ໂປຣໂມຊັນທີ່ Shiba888 ມີຫຼາຍກວ່າ ແລະແຕກງ່າຍກວ່າ.',
    link_partner: 'ລິງກ໌ຮັບຊັບສົມບັດ',
    deposit_title: 'ຈຳນວນເງິນຝາກ',
    deposit_title2: 'ຈຳນວນເງິນເສຍ',
    deposit_title3: 'ສົ່ງລິງກ໌ໄດ້ທຸກຊ່ອງທາງເພື່ອຮັບຊັບສົມບັດກັບພວກເຮົາ.',
    copy: 'ຄັດລອກ',
    partner_title: 'ລາຍໄດ້ຈາກການແນະນຳ',
    partner_title2: 'ແນະນຳໝູ່',
    partner_title3: 'ໝູ່ແນະນຳໝູ່',
    partner_title4: 'ລາຍໄດ້ຈາກການແນະນຳໝູ່',
    partner_title5: 'ລາຍໄດ້ຈາກໝູ່ແນະນຳໝູ່',
    partner_title6: 'ລາຍໄດ້ທັງໝົດ',
    partner_title7: 'ໂອນເງິນເຂົ້າກະເປົາຫຼັກ',
    deposit_type: {
      deposit: 'ຈຳນວນເງິນຝາກ',
      loss: 'ຈຳນວນເງິນເສຍ'
    },
    login_page: {
      mobile: 'ເບີໂທ',
      forgot_password: 'ລືມລະຫັດຜ່ານ?',
      contact_admin: 'ຕິດຕໍ່ Admin',
      password: 'ລະຫັດຜ່ານ',
      login_with: 'ເຂົ້າລະບົບ',
      login_with_line: 'ເຂົ້າລະບົບຜ່ານ LINE',
      login_with_facebook: 'ເຂົ້າລະບົບຜ່ານ Facebook',
      login_with_google: 'ເຂົ້າລະບົບຜ່ານ Google',
      no_account: 'ບໍ່ມີບັນຊີອີກບໍ?',
      register: 'ລົງທະບຽນ',
      change_password: 'ປ່ຽນລະຫັດຜ່ານ',
      confirm_mobile_account: 'ກະລຸນາປ້ອນເບີໂທແລະເລກບັນຊີ.',
      account_number: 'ເລກບັນຊີ',
      new_password: 'ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່.',
      new_password2: 'ລະຫັດຜ່ານໃໝ່',
      confirm_new_password: 'ຢືນຢັນລະຫັດຜ່ານໃໝ່',
      confirm: 'ຢືນຢັນ',
      please_check_password: 'ກະລຸນາກວດສອບລະຫັດຜ່ານໃຫ້ຖືກຕ້ອງ.',
      text_header: 'ຂໍ້ຄວາມ!',
      change_password_success: 'ປ່ຽນລະຫັດຜ່ານສຳເລັດແລ້ວ.',
      please_fill_data: 'ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບ!',
      please_contact_admin: 'ກະລຸນາຕິດຕໍ່ Admin @shiba888vip',
      mobile_password_error: 'ເບີໂທ / ລະຫັດຜ່ານ ບໍ່ຖືກຕ້ອງ!',
    },
    register_page: {
      title: 'ລົງທະບຽນ',
      register_success: 'ການລົງທະບຽນຂອງທ່ານສຳເລັດແລ້ວ.',
      please_fill_data: 'ກະລຸນາປ້ອນຂໍ້ມູນຂອງທ່ານ.',
      please_fill_password: 'ກະລຸນາປ້ອນລະຫັດຜ່ານຂອງທ່ານ.',
      confirm_password: 'ຢືນຢັນລະຫັດຜ່ານ',
      next: 'ຖັດໄປ',
      back: 'ກັບຄືນ',
      please_select_bank: 'ກະລຸນາເລືອກທະນາຄານຂອງທ່ານ.',
      select_bank: 'ເລືອກທະນາຄານ',
      account_number: 'ເລກບັນຊີ',
      firstname: 'ຊື່',
      lastname: 'ນາມສະກຸນ',
      register: 'ລົງທະບຽນ',
      thank_for_register: 'ຂອບໃຈສໍາລັບການລົງທະບຽນ. ທ່ານສາມາດໃຊ້ຊື່ແລະລະຫັດຜ່ານຂ້າງລຸ່ມເພື່ອເຂົ້າລະບົບ.',
      username: 'ຊື່ຜູ້ໃຊ້',
      password: 'ລະຫັດຜ່ານ',
      login: 'ເຂົ້າລະບົບ',
      or: 'ຫຼື',
      have_account: 'ທ່ານມີບັນຊີແລ້ວບໍ?',
      contact_admin: 'ຕິດຕໍ່ Admin',
      fill_account_no: 'ກະລຸນາປ້ອນເລກບັນຊີ!',
      fill_name: 'ກະລຸນາປ້ອນຊື່!',
      fill_lastname: 'ກະລຸນາປ້ອນນາມສະກຸນ!',
      please_contact_admin: 'ກະລຸນາຕິດຕໍ່ Admin @shiba888vip ເພື່ອແຈ້ງບັນຫາ.',
      name_exist: 'ຊື່ນີ້ຖືກລົງທະບຽນແລ້ວ. ຖ້າທ່ານມີບັນຫາ, ກະລຸນາຕິດຕໍ່ @shiba888vip.',
      check_password_length: 'ກະລຸນາປ້ອນລະຫັດຜ່ານຢ່າງໜ້ອຍ 6 ຕົວ.',
      check_password_not_match: 'ລະຫັດຜ່ານບໍ່ກົງກັນ!',
      fill_mobile: 'ກະລຸນາປ້ອນເບີໂທ!',
      check_mobile_length: 'ກະລຸນາປ້ອນເບີໂທຢ່າງໜ້ອຍ 10 ຕົວ.',
      mobile_exist: 'ເບີໂທນີ້ໄດ້ລົງທະບຽນແລ້ວ. ຖ້າທ່ານມີບັນຫາ, ກະລຸນາຕິດຕໍ່ @shiba888vip.',
      account_no_exist: 'ເລກບັນຊີນີ້ໄດ້ລົງທະບຽນແລ້ວ. ຖ້າທ່ານມີບັນຫາ, ກະລຸນາຕິດຕໍ່ @shiba888.',
    },
    home_page: {
      firstpage: 'ໜ້າຫຼັກ',
      contactus: 'ຕິດຕໍ່ພວກເຮົາ',
      firsttitle: 'ຍິນດີຕ້ອນຮັບສູ່ Shiba888 ທີ່ທີ່ດີທີ່ສຸດໃນໂລກຂອງການພະນັນ',
      checkin_error: 'ຍອດຝາກປະຈຳວັນຄົບ 100 Check-In ຟຣີພ້ອມແລ້ວ!',
      checkin_success: 'ທ່ານ Check-in ສຳເລັດແລ້ວ.',
      checkin_error2: 'ທ່ານ Check-in ປະຈຳວັນແລ້ວ. ກະລຸນາກັບມາໃນມື້ອື່ນ!',
      account: 'ຂໍ້ມູນບັນຊີ',
      logout: 'ອອກຈາກລະບົບ',
      change_password: 'ປ່ຽນລະຫັດຜ່ານ',
      contact: `<label class="-text-message ">ມີບັນຫາ?</label>
                      <a href="https://lin.ee/L8YOLQ7" class="-link-message " target="_blank" rel="noopener">
                        <u>ຕິດຕໍ່ພະນັກງານບໍລິການລູກຄ້າ</u>
                      </a>`,
      password: 'ລະຫັດຜ່ານປະຈຸບັນ',
      new_password: 'ລະຫັດຜ່ານໃໝ່',
      new_password2: 'ຢືນຢັນລະຫັດຜ່ານໃໝ່',
      confirm: 'ຢືນຢັນ',
      line_login: 'ເຂົ້າລະບົບຜ່ານ LINE',
      facebook_login: 'ເຂົ້າລະບົບຜ່ານ Facebook',
      google_login: 'ເຂົ້າລະບົບຜ່ານ Google',
      want_withdraw: 'ການຂໍເງິນຖອນ',
      turndone: `ທ່ານໄດ້ສຳເລັດ turnover ທີ່ຕ້ອງການແລ້ວ! ທ່ານສາມາດຖອນ {canwithdraw} ບາດ.<br> ກົດປຸ່ມຢືນຢັນເພື່ອດຳເນີນການ.`,
      turnfail: `ທ່ານຈະຕ້ອງເຮັດ turnover {turncredit}.`,
      slip_success: 'ສົ່ງ slip ສຳເລັດແລ້ວ.',
      slip_sorry: 'ຂໍອະໄພສຳລັບຄວາມລ່າຊ້າ, ພວກເຮົາຈະດຳເນີນການໃຫ້ໄວທີ່ສຸດ.',
      logout_confirm: 'ຢືນຢັນອອກຈາກລະບົບ',
      withdraw_confirm: 'ຢືນຢັນການຖອນ',
      withdraw_sorry: 'ຂໍອະໄພ, ທ່ານໄດ້ຖອນຄົບ 3 ຄັ້ງແລ້ວ. ລອງໃໝ່ຫຼັງສຽງການຄືນ.',
      withdraw_max: 'ທ່ານສາມາດຖອນສູງສຸດ 450,000 ບາດ/ວັນ.',
      withdraw_success: 'ສຳເລັດ! ກະລຸນາລໍຖ້າຂອງທ່ານ.',
      withdraw_truewallet: 'ສຳລັບ TrueWallet ຕ້ອງຝາກຂັ້ນຕ່ຳ 100 ບາດ.',
      withdraw_truewallet2: '1. ຈຳນວນຕ້ອງຫຼາຍກວ່າ 100 <br>2. ຈົບລົງທ້າຍໃນ 0 ຕົວຢ່າງ 110, 220.',
      withdraw_waiting: 'ກະລຸນາລໍຖ້າການອະນຸມັດກ່ອນໜ້ານີ້.',
      withdraw_credit: 'ກະລຸນາກວດສອບຈຳນວນເຄຣດິດ.',
      withdraw_credit2: 'ກະລຸນາກວດສອບຂັ້ນຕ່ຳ (50).',
      password_length: 'ກະລຸນາໃສ່ລະຫັດຜ່ານຫຼາຍກວ່າ 6 ຕົວ.',
      password_confirm: 'ກວດສອບລະຫັດຜ່ານໃໝ່.',
      password_success: 'ປ່ຽນລະຫັດຜ່ານສຳເລັດ!',
      password_old: 'ກວດສອບລະຫັດປະຈຸບັນ.',
    },
    boxs_page: {
      title: 'ຫີບສົມບັດ',
      title2: '**ເງື່ອນໄຂ: ຍອດເສຍລວມປະຈຳວັນ 250 ບາດ ຮັບສິດທິໄດ້ທັນທີ**',
      message: '💎 ທ່ານໄດ້ເປີດຫີບສົມບັດແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນນະ 😘',
      message2: 'ຍອດເສຍມື້ວານຂາດອີກ {less}(250) ບາດ! 😘😘',
    },
    spin_page: {
      title: 'ວົງລໍຮັບເງິນຟຣີ',
      title2: '**ເງື່ອນໄຂ: ຝາກເງິນຄົບ 500 ບາດປະຈຳວັນ ຮັບສິດທິທັນທີ**',
      message: '🎡 ທ່ານໄດ້ຫມຸນວົງລໍຂອງພວກເຮົາແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນ 😘',
      message2: 'ເພີ່ມເງິນອີກ {less}(500) ຈຶ່ງສາມາດຫມຸນໄດ້! 😘😘',
      success: '🎡 ສຸດຍອດ! ທ່ານໄດ້ຮັບ {winner} 😘',
      credit: 'ເຄຣດິດ',
      point: 'ຄະແນນ',
    },
    roulet_page: {
      title: 'ຮູເລັດຮັບເຄຣດິດຟຣີ',
      title2: '**ເງື່ອນໄຂ: ຝາກເງິນຄົບ 1,000 ບາດປະຈຳວັນ ຮັບສິດທິທັນທີ**',
      message: '🏟 ທ່ານໄດ້ຫມຸນຮູເລັດແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນ 😘',
      message2: 'ເພີ່ມເງິນອີກ {less}(1,000) ຈຶ່ງສາມາດຫມຸນໄດ້! 😘😘',
      fail: '🏟 TT ເສົ້າທີ່ສຸດ 😘😘',
      success: '🏟 ສຸດຍອດ! ທ່ານໄດ້ຮັບ {winner} ເຄຣດິດ 😘😘',
      spin: 'ຫມຸນໄດ້ເລີຍ',
    },
    gashapon_page: {
      title: 'ກາຊາປອງ',
      title2: '**ເງື່ອນໄຂ: ຍອດເສຍລວມ 500 ບາດປະຈຳວັນ ຮັບສິດທິທັນທີ**',
      message: '💎 ທ່ານໄດ້ຫມຸນກາຊາປອງແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນ 😘',
      message2: 'ຍອດເສຍມື້ວານຂາດອີກ {less}(500) ບາດ! 😘😘',
      success: '💎 ສຸດຍອດ! ທ່ານໄດ້ຮັບ {winner} ກີບ 😘😘',
    },
    paoyingchup_page: {
      title: 'ຄອນ-ກະດາດ-ກີໂຕ',
      title2: '**ເງື່ອນໄຂ: ຍອດເສຍລວມ 1,000 ບາດປະຈຳວັນ ຮັບສິດທິເລີ່ມຫຼິ້ນ**',
      message: '✌️ ທ່ານຫຼິ້ນຄອນ-ກະດາດ-ກີໂຕຂອງພວກເຮົາແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນ 😘',
      message2: 'ຍອດເສຍມື້ວານຂາດອີກ {less}(1,000) ບາດ! 😘😘',
      fail1: 'ເສຍສຽນ! ຂໍໂຊກດີໃນຄັ້ງໜ້າ 😢',
      fail2: 'ເຈົ້າແພ້ແລ້ວ! ຂໍໂທດທີ່ເສຍໃຈ 😢',
      success: '✌️ ສຸດຍອດ! ທ່ານໄດ້ຮັບ {item} ເຫຼີຍ 😘😘',
    },
    huekoy_page: {
      title: 'ຫົວກ້ອຍ',
      title2: '**ເງື່ອນໄຂ: ຍອດເສຍສອງມື້ຕິດຕໍ່ກັນ ແລະມີຍອດເສຍລວມຫຼາຍກວ່າ 300 ຈະຮັບສິດທິທັນທີ**',
      success: '💎 ສຸດຍອດ! ທ່ານໄດ້ຮັບ 40 ເຫຼີຍ 😘😘',
      fail: 'ຂໍໂທດທີ່ເສຍໃຈ 😢',
      message: '💎 ທ່ານໄດ້ຫຼິ້ນຫົວກ້ອຍແລ້ວມື້ນີ້. ກະລຸນາກັບມາໃນມື້ອື່ນ 😘',
      message2: '💎 ທ່ານບໍ່ມີຍອດເສຍສອງມື້ຕິດຕໍ່ກັນ 😘',
      message3: '💎 ທ່ານຕ້ອງມີຍອດເສຍສອງມື້ທີ່ລວມຫຼາຍກວ່າ {checkcashback} 😘',
      message4: '💎 ຍອດເສຍຂາດອີກ {less}(300) 😘😘',
      message5: 'ພ້ອມຫຼິ້ນໄດ້ແລ້ວ! 😘😘',
    },
    huay_page: {
      title: 'ແທງຫວຍບໍ່ຈົດຈຳກັດ',
      title2: '**ເງື່ອນໄຂ: ຝາກ 200 ບາດທຸກຄັ້ງຮັບສິດທິສຳລັບວັນອອກຫວຍເທົ່ານັ້ນ**',
      description: '**ທຸກຍອດຝາກ 200 ບາດ ຮັບສິດທິໃນການແທງເລກສອງຫົວລ້າງຂອງລັດຖະບານທັນທີ. ຖ້າຖືກ ຮັບ 500 ເຄຣດິດຟຣີ! ຖ້າພົບການໂກງຄົ້ນຈະຖືກຕັດສິດທິທັນທີ.**',
      confirm: 'ຢືນຢັນແທງຫວຍ',
      success: 'ທ່ານໄດ້ແທງຫວຍສຳເລັດແລ້ວ 😘',
      warning: 'ກະລຸນາກວດສອບຈຳນວນສິດທິທີ່ແທງ.',
    },
    rewards_page: {
      title: 'ເພີ່ມຄວາມຫຼາກຫຼາຍຂອງລາງວັນທີ່ Shiba888',
      description: 'ໃຊ້ເຫຼີຍສຳລັບລົງເງິນເຄຣດິດໄດ້ທັນທີ.',
      detail: 'ເຮັດ turnover {turn} ຄັ້ງ ຖອນໄດ້ສູງສຸດ {maxwithdraw} ຄັ້ງ.',
      confirm: 'ຢືນຢັນ',
      cancel: 'ຍົກເລີກ',
      warning: 'ບໍ່ສາມາດແລກເຄຣດິດໄດ້ເນື່ອງຈາກຍອດເຫຼືອຫຼາຍກວ່າ 5 ບາດ.',
      warning2: 'ທ່ານມີຫຼາຍກວ່າ 5 ເຄຣດິດ ບໍ່ສາມາດຫຼິ້ນໄດ້!',
      title2: 'ແລກເຄຣດິດ',
    },
    partner_page: {
      share_link_title: 'ແບ່ງປັນລິງກ໌ເພື່ອຮັບລາຍໄດ້ <span style="color:#FAB537">{bonus}%</span> ຟຣີທຸກ {type}',
      share_more: 'ຍິ່ງແບ່ງປັນຫຼາຍ ຍິ່ງໄດ້ຫຼາຍ',
      copy_to_earn: 'ຄັດລອກແລະແບ່ງປັນລິງກ໌ເພື່ອເລີ່ມສ້າງລາຍໄດ້',
      share_description: 'ທ່ານສາມາດແບ່ງປັນລິງກ໌ນີ້ຜ່ານຊ່ອງທາງຕ່າງໆເຊັ່ນ ບລັອກ Facebook ຫຼືເຄືອຂ່າຍສັງຄົມອື່ນໆ. ທຸກຄົນທີ່ສະໝັກຜ່ານລິງກ໌ຂອງທ່ານ ຈະຢູ່ໃນຂ່າຍຂອງທ່ານ ທຸກການລົງທຶນທີ່ພວກເຂົາເຮັດ ທ່ານຈະໄດ້ຮັບ {bonus}% ຄ່າຄອມມິຊັນທັນທີ.',
      daily_earn: 'ຮັບລາຍໄດ້ທຸກມື້ໂດຍບໍ່ມີວັນໝົດອາຍຸ',
      calculation_example: 'ລະບົບຄິດໄລ່: ຕົວຢ່າງ ຖ້າຜູ້ແນະນຳຂອງທ່ານມີ {type} ລວມ 200,000 x {bonus}% = {result} ກີບ/ມື້ເປັນລາຍໄດ້ຖາວອນ',
      lifetime_commission: 'ຍິ່ງແນະນຳຫຼາຍ ຍິ່ງມີລາຍໄດ້ຫຼາຍ ໂອນເຂົ້າກະເປົາຫຼັກທັນທີ ພຽງແນະນຳພວກເຂົາເຂົ້າມາຢູ່ໃຕ້ຂອງທ່ານ ເພື່ອຮັບຄ່າຄອມມິຊັນຟຣີຕະຫຼອດຊີວິດ',
      monthly_potential: 'ຮັບລາຍໄດ້ງ່າຍໆ 100,000 ກີບ/ເດືອນ. ລາຍໄດ້ທຸກກີບສາມາດກວດສອບໄດ້ທຸກຂັ້ນຕອນ. ລາງວັນຈິງ ການຈ່າຍເງິນຈິງ. ທີ່ນີ້ໃຫ້ທ່ານຫຼາຍກວ່າທຸກທີ່. ຄັດລອກລິງກ໌ແລະເລີ່ມແບ່ງປັນເລີຍ'
    },
    cashback_page: {
      title: 'ຄືນເງິນ',
      title2: 'ຍອດເງິນ',
      description: `<p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1" style="font-size:20px;">ຮັບຄືນເງິນ <font color="#FAB537">ທຸກມື້ເວລາ 09:00</font>. ສູນເສຍຮັບຄືນ <font color="#FAB537">5%</font>. ວ້າວ!</b>
                </p>
                <p style="text-align: center;padding:0px;font-size: 14px;font-family: Kanit;color:#000;">
                    <b class="tt_l tt_full fr_tx1">** ຖອນໄດ້ທັນທີ ຫຼື ສືບຕໍ່ການເລີ່ມສ້າງໄດ້ **</b>
                </p>`,
    },
    history_page: {
      title: 'ປະຫວັດການຄືນເງິນ',
      title2: 'ວັນ/ເວລາ',
      title3: 'ປະເພດ',
      title4: 'ຈຳນວນ',
      title5: 'ສະຖານະ',
      no_data: 'ບໍ່ມີຂ້ອມູນ',
    },
    continue_page: {
      title: 'ຝາກຕໍ່ເນື່ອງ',
      title2: 'ຝາກຕໍ່ເນື່ອງ 7 ວັນ',
      title3: 'ໂບນັສ',
      title4: 'ຝາກຕໍ່ເນື່ອງ ຮັບໄດ້ທຸກມື້ເວລາ 7:00 ນ.',
    },
    images: {
      title: require('@/assets/images/language/lo/title.png'),
      play:'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fplay%2FLoading_Laos.png?alt=media&token=c4cfc093-0592-41cc-9e74-9e40e957f10d',
      menu: {
        promotion: require('@/assets/images/language/lo/07.png'),
        history: require('@/assets/images/language/lo/05.png'),
        withdraw: require('@/assets/images/language/lo/04.png'),
        play: require('@/assets/images/language/lo/13.png'),
        deposit: require('@/assets/images/language/lo/03.png'),
        paoyingchup: require('@/assets/images/language/lo/01.png'),
        gashapon: require('@/assets/images/language/lo/16.png'),
        checkin: require('@/assets/images/language/lo/02.png'),
        spin: require('@/assets/images/language/lo/09.png'),
        roulet: require('@/assets/images/language/lo/10.png'),
        boxs: require('@/assets/images/language/lo/17.png'),
        huekoy: require('@/assets/images/language/lo/14.png'),
        huay: require('@/assets/images/language/lo/15.png'),
        continue: require('@/assets/images/language/lo/12.png'),
        partner: require('@/assets/images/language/lo/06.png'),
        cashback: require('@/assets/images/language/lo/08.png'),
        rewards: require('@/assets/images/language/lo/11.png'),
      },
      banners: {
        banner1: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F01.png?alt=media',
        banner2: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F02.png?alt=media',
        banner3: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F03.png?alt=media',
        banner4: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F04.png?alt=media',
        banner5: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F05.png?alt=media',
        banner6: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F06.png?alt=media',
        banner7: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F07.png?alt=media',
        banner8: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F08.png?alt=media',
        banner9: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F09.png?alt=media',
        banner10: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F10.png?alt=media',
        banner11: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F11.png?alt=media',
        banner12: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F12.png?alt=media',
        banner13: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F13.png?alt=media',
        banner14: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F14.png?alt=media',
        banner15: 'https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Fbanners%2Fwinter%2Flo%2F15.png?alt=media',
      }
    }
  }
}

const i18n = new VueI18n({
  locale: 'th', // ภาษาเริ่มต้น
  fallbackLocale: 'th', // ภาษาสำรองถ้าไม่พบคำแปล
  messages
})

export default i18n