<template>
  <div>
		<div class="preloader" v-if="loading">
        <div class="circular-spinner">
          <img src="../assets/images/shiba-loading.png">
        </div>
    </div>
    <div class="container">
      <div class="x-language">
						<div class="current-lang -link-wrapper" @click="toggleDropdown">
							<div class="-image-wrapper">
								<img 
									class="img-fluid -ic-right-star-rank" 
									:src="getFlagImage()" 
									alt="Shiba888 change language"
								>
								<span class="lang-name" v-if="isDropdownOpen">{{ getLanguageName() }}</span>
							</div>
						</div>
						<div class="lang-dropdown" v-if="isDropdownOpen">
							<div 
								v-for="lang in languages" 
								:key="lang.code"
								class="lang-option"
								@click="selectLanguage(lang.code)"
							>
								<img 
								:src="require(`../assets/images/flags/${lang.code}.png`)" 
								:alt="lang.name"
								class="flag-icon"
								>
								<span class="lang-name">{{ lang.name }}</span>
							</div>
						</div>
			</div>
      <div class="signup-space">
        <div class="signup-stars"></div>
        <div class="signup-stars"></div>
        <div class="signup-stars"></div>
        <div class="signup-stars"></div>
        <div class="signup-stars"></div>
        <div class="signup-stars"></div>
      </div>
      <img src="https://github.githubassets.com/images/modules/site/home/hero-glow.svg" alt="Glowing universe" class="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none">
        <div class="row">
            <div class="col-md-5 mx-auto">
                <div id="first">
                    <div class="myform form ">
                        <div class="logo mb-3">
                            <div class="col-md-12 text-center">
                                <img src="../assets/images/logo-shiba8881.png"
                                    style="max-width: 100%;">
                                <p class="mt-3 text-white small"></p>
                            </div>
                        </div>
                        <form id="frm-login" name="frm-login" method="post" onsubmit="return false;">
                            <input type="hidden" id="_token" name="tokencsrf"
                                value="126babe7225c2c5e7e51356f2eae4e4c">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1" style="padding: 13px;">
                                            <i class="fa fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="number" class="form-control" :placeholder="$t('login_page.mobile')"
                                        v-model="mobile" id="mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10"
                                        aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon2" style="max-width: 37.4px;">
                                            <i class="fa fa-key" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <input type="password" id="password" name="password" class="form-control"
                                        :placeholder="$t('login_page.password')" aria-label="password" v-model="password" aria-describedby="basic-addon2">
                                    <div class="input-group-append showpass" data-isshow="false">
                                        <span class="input-group-text"><i class="fa fa-eye-slash"
                                                aria-hidden="true"></i></span>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group">
                                <p class="text-right">
                                    <a id="btn-forgotModal" href="javascript:;" data-toggle="modal" data-target="#forgotModal" style="color: #ccc;">{{ $t('login_page.forgot_password') }}</a> / <a href="javascript:;" @click="openLine()" style="color: #ccc;">{{ $t('login_page.contact_admin') }}</a>
                                </p>
                            </div>
                            <div class="col-md-12 text-center " style="padding-left:0px;padding-right:0px;">
                                <button type="button" class="google btn mybtn" style="border: 1px solid lightgrey;" @click="login()">{{ $t('login_page.login_with') }}</button>
                            </div>
                            <div class="col-md-12 text-center" style="padding-top:30px;padding-left:0px;padding-right:0px;">
                                <button type="button" class="google btn mybtn cCsozd ewHin" @click="lineLogin()"><img class="icon" src="../assets/images/icon-line.svg" alt="line">{{ $t('login_page.login_with_line') }}</button>
                            </div>
                            <div class="col-md-12 text-center" style="padding-top:10px;padding-left:0px;padding-right:0px;">
                                <button type="button" class="google btn mybtn cCsozd ewHin" style="background:#0782ED" @click="facebookLogin()"><img class="icon" src="../assets/images/facebook.png" alt="line">{{ $t('login_page.login_with_facebook') }}</button>
                            </div>
                            <div class="col-md-12 text-center" style="padding-top:10px;padding-left:0px;padding-right:0px;">
                                <button type="button" class="google btn mybtn cCsozd ewHin" style="background:#FFF;color:#000;" @click="googleLogin()"><img class="icon" src="../assets/images/google.png" alt="line">{{ $t('login_page.login_with_google') }}</button>
                            </div>
                            <div class="col-md-12 ">
                                <div class="login-or">
                                    <hr class="hr-or">
                                    <span class="span-or">{{ $t('login_page.no_account') }}</span>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3" style="padding-top:10px;padding-left:0px;padding-right:0px;">
                                <p class="text-center">
                                    <router-link to="/register" class="btn btn-blu-grow">{{ $t('login_page.register') }}</router-link>
                                </p>
                            </div>
                            <!-- <div class="form-group">
                                <p class="text-center"><a href="https://shib1688.com/" id="signup" style="color: #aaa;"><i
                                            class="fa fa-reply" aria-hidden="true"></i> กลับไปหน้าแรก</a></p>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="forgotModal" tabindex="-1" role="dialog" aria-labelledby="forgotModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="forgotModalLabel" style="color: #fec33f;">{{ $t('login_page.change_password') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="color: #FFF;opacity: 1;font-weight: 400;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body msformforgot">
                        <fieldset>
                            <p class="text-center">{{ $t('login_page.please_fill_mobile_and_account') }}</p>
                            <div class="myform" style="padding: 0;background: none;">
                                <div class="form-group ">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" :placeholder="$t('login_page.mobile')" v-model="forgot_mobile"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10"
                                            aria-describedby="basic-addon1">
                                    </div>
                                </div>

                                <div class="form-group " >
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2" style="padding: 0.375rem 9px;">
                                            <i class="fa fa-id-card-o" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" :placeholder="$t('login_page.account_number')" id="accountnumber" name="accountnumber" v-model="forgot_account" aria-describedby="basic-addon2">
                                    </div>
                                </div>

                                <p class="text-center">{{ $t('login_page.new_password') }}</p>

								<div class="form-group">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2">
                                                <i class="fa fa-key"></i>
                                            </span>
                                        </div>
                                        <input type="password" class="form-control" :placeholder="$t('login_page.new_password2')"
                                            id="newpassword" name="newpassword" aria-label="email" v-model="forgot_password"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>

								<div class="form-group">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2">
                                                <i class="fa fa-key"></i>
                                            </span>
                                        </div>

                                        <input type="password" class="form-control" :placeholder="$t('login_page.confirm_new_password')"
                                            id="con_newpassword" name="con_newpassword" aria-label="email" v-model="forgot_password2"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>

                            </div>

                            <div class="modal-footer" style="border-top: none;">
                                <button type="button" id="btn_change_password" class=" action-button btn btn-block mybtn icon  btn_login tx-tfm" @click="forgotPassword()">{{ $t('login_page.confirm') }}</button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
	  </div>
  </div>
</template>

<script>
import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
const axios = require('axios').default
// import OAuthRequest from '@team-decorate/src/Entities/OAuthRequest'
const qs = require('qs')
// import OAuthResult from '@team-decorate/src/Entities/OAuthResult'
export default {
  name: 'Login',
  async created () {
    const { code } = queryString.parse(window.location.search.replace('?', ''))
    if (!code) return
    const result = await this.getToken(code)
    if (result && result.access_token) {
      const { data } = await this.getProfile(result.access_token)
      this.checkLineLogin(data)
    }
    // if(this.friendRequired) {
    //     const flag = await this.checkFriend(result.token)
    //     if(!flag) {
    //         this.error = this.friendErrorTest
    //     }
    // }
    // if(!this.error) {
    //     const response = Object.assign(data, result.getPostable())
    //     console.log(response);
    // }
  },
  data () {
    return {
      isDropdownOpen: false,
      languages: [
        { code: 'th', name: 'ไทย' },
        { code: 'en', name: 'English' },
        { code: 'my', name: 'Myanmar' },
        { code: 'lo', name: 'ລາວ' }
      ],
      pictureUrl: '',
      name: '',
      logo: '',
      login_color: '#1e6ee8',
      error: '',
      link: '',
      mobile: '',
      password: '',
      web: [],
      change: false,
      loading: false,
      clientId: '1656935750',
      callbackUri: 'https://shiba888.bet/login',
      clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
      addFriend: false,
      friendRequired: false,
      friendErrorTest: '',
      forgot_mobile:'',
      forgot_account:'',
      forgot_password:'',
      forgot_password2:'',
    }
  },
  mounted () {
    // this.getSetting();
    $('body').on('click', '.showpass', function () {
      let isShow = $(this).attr('data-isshow')
      const pType = isShow == 'false' ? 'text' : 'password'
      isShow = isShow == 'false' ? 'true' : 'false'
      $(this).attr('data-isshow', isShow)
      if (pType == 'text') {
        $(this).find('i').attr('class', 'fa fa-eye')
      } else {
        $(this).find('i').attr('class', 'fa fa-eye-slash')
      }
      $('#password').attr('type', pType)
    })
    $('body').on('click', '#btn-forgotModal', function () {
      $('.msformforgot fieldset:not(:first-of-type)').css({ display: 'none' })

      const fEle = $('.msformforgot fieldset:first-child')
      fEle.css({ display: 'block', opacity: 1 })
    })
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const newLang = userData.language;
    if (newLang) {
			this.$i18n.locale = newLang;
		} else {
			this.$i18n.locale = 'th'; // default to Thai
		}
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLanguage(langCode) {
      // Update user language in database
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      userData.language = langCode;
      localStorage.setItem('userData', JSON.stringify(userData));
      
      // Update Vuex store
      this.$i18n.locale = langCode;
      
      this.isDropdownOpen = false;
    },
    getLanguageName () {
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      const language = userData.language;
      const validLanguages = ['my', 'en', 'lo', 'th'];
      if (validLanguages.includes(language)) {
        return this.languages.find(lang => lang.code === language).name;
      }
      return 'ไทย';
    },
    getFlagImage() {
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      const language = userData.language;
      const validLanguages = ['my', 'en', 'lo', 'th'];
      
      if (validLanguages.includes(language)) {
      return require(`../assets/images/flags/${language}.png`);
      }
      
      return require('../assets/images/flags/th.png');
    },
    openLine () {
      window.location = 'https://lin.ee/L8YOLQ7'
    },
    forgotPassword() {
      if(this.forgot_mobile && this.forgot_account && this.forgot_password && this.forgot_password2){
        if(this.forgot_password.trim() !== this.forgot_password2.trim()){
          this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_check_password') })
        }else{
          axios({
            method: 'post',
            url: 'https://api.shiba888.bet/api/forgotpassword',
            data: {
              mobile: this.forgot_mobile.trim(),
              account: this.forgot_account.trim(),
              newpassword: this.forgot_password.trim()
            }
          }).then(async res => {
              if (res && res.data && res.data.status) {
                $("#forgotModal").modal('hide')
                this.$swal({ icon: 'success', title: this.$t('login_page.text_header'), text: this.$t('login_page.change_password_success') })
              }else if(res.data.error){
                this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: res.data.error })
              }
          });
        }
      }else{
        this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_fill_data') })
      }
    },
    checkLineLogin (data) {
      this.loading = true
      axios({
        method: 'post',
        url: 'https://api.shiba888.bet/api/loginline',
        data: {
          userId: data.userId,
          pictureUrl: data.pictureUrl
        }
      }).then(async res => {
        if (res && res.data && res.data.status) {
          firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res2) => {
            if (res2.user) {
              localStorage.setItem('userData', JSON.stringify(res.data))
              this.loading = false
              router.replace('/')
            } else {
              this.error = this.$t('login_page.please_contact_admin')
            }
            this.loading = false
          }).catch(() => {
            this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.mobile_password_error') })
            this.loading = false
          })
        }else{
          localStorage.setItem('userId', JSON.stringify(data))
          router.replace('/register')
          this.loading = false
        }
      })
    },
    openLine () {
      window.location = 'https://lin.ee/L8YOLQ7'
    },
    login () {
      if (!this.mobile) {
        this.$swal({ icon: 'warning', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_fill_mobile') })
        $('#mobile').focus()
      } else if (!this.password) {
        this.$swal({ icon: 'warning', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_fill_password') })
        $('#pass').focus()
      } else {
        this.loading = true
        const keygen = Math.random().toString(32).substring(2)
        axios({
          method: 'post',
          url: 'https://api.shiba888.bet/api/login',
          data: {
            mobile: this.mobile,
            password: this.password,
            keygen: keygen
          }
        }).then(async res => {
          if (res.data && !res.data.status) {
            this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.mobile_password_error') })
            $('#mobile').focus()
            this.loading = false
          } else {
            firebase.auth().signInWithEmailAndPassword(this.mobile.trim().toLowerCase() + '@shiba888.com', this.password.trim()).then(async (res2) => {
              if (res2.user) {
                res.data.keygen = keygen
                localStorage.setItem('userData', JSON.stringify(res.data))
                this.loading = false
                router.replace('/')
              } else {
                this.error = this.$t('login_page.please_contact_admin')
              }
              this.loading = false
            }).catch(() => {
              this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.mobile_password_error') })
              this.loading = false
            })
          }
        })
      }
    },
    lineLogin () {
      const url = 'https://access.line.me/oauth2/v2.1/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid profile'
      }
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: 'consent',
          bot_prompt: 'aggressive'
        })
      }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
    googleLogin() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then((result) => {
        var credential = result.credential;
        var token = credential.accessToken;
        var user = result.user;
        console.log('credential',credential);
        console.log('token',token);
        console.log('user',user);
        if(user && user.email){
            this.loading = true
            axios({
              method: 'post',
              url: 'https://api.shiba888.bet/api/logingoogle',
              data: {
                displayName: user.displayName,
                email: user.email
              }
            }).then(async res => {
              if (res && res.data && res.data.status) {
                firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res2) => {
                  if (res2.user) {
                    localStorage.setItem('userData', JSON.stringify(res.data))
                    this.loading = false
                    router.replace('/')
                  } else {
                    this.error = this.$t('login_page.please_contact_admin')
                  }
                  this.loading = false
                }).catch(() => {
                  this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.mobile_password_error') })
                  this.loading = false
                })
              }else{
                localStorage.setItem('google', JSON.stringify(user))
                router.replace('/register')
                this.loading = false
              }
            })
          }
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    },
    facebookLogin() {
      var provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('email');
      // provider.setCustomParameters({
      //   'display': 'popup'
      // });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var credential = result.credential;

          // The signed-in user info.
          var user = result.user;
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var accessToken = credential.accessToken;
          if(user && user.email){
            this.loading = true
            axios({
              method: 'post',
              url: 'https://api.shiba888.bet/api/loginfacebook',
              data: {
                displayName: user.displayName,
                email: user.email
              }
            }).then(async res => {
              if (res && res.data && res.data.status) {
                firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res2) => {
                  if (res2.user) {
                    localStorage.setItem('userData', JSON.stringify(res.data))
                    this.loading = false
                    router.replace('/')
                  } else {
                    this.error = this.$t('login_page.please_contact_admin')
                  }
                  this.loading = false
                }).catch(() => {
                  this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.mobile_password_error') })
                  this.loading = false
                })
              }else{
                localStorage.setItem('faceBook', JSON.stringify(user))
                router.replace('/register')
                this.loading = false
              }
            })
          }
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;

          // ...
        });
    },
    async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = this.$t('login_page.please_contact_admin')
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
    async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
    async checkFriend (token) {
      const { data } = await axios.get('https://api.line.me/friendship/v1/status', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data.friendFlag
    },
    checkShowPassword () {
      var x = document.getElementById('pass')
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    },
    gotoRegister () {
      router.replace('/register')
    }
  }
}
</script>
<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
</style>
<style scoped>
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}

.home-hero-glow {
    top: 50%;
    left: 50%;
    width: 200%;
    transform: translate(-50%, -50%);
}
.events-none {
    pointer-events: none;
}
.overflow-hidden {
    overflow: hidden !important;
}
.position-absolute {
    position: absolute !important;
}
</style>
<style lang="scss">
.signup-space,
.signup-stars {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.signup-stars {
  background-image:
    radial-gradient(2px 2px at 50px 200px, #eee, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(3px 4px at 120px 40px, #ddd, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 250px 250px;
  opacity: 0;
  animation: zoom 5s infinite;

  &:nth-child(1) {
    background-position: 10% 90%;
    animation-delay: 0s;
  }

  &:nth-child(2) {
    background-position: 20% 50%;
    background-size: 270px 500px;
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    background-position: 40% -80%;
    animation-delay: 1.2s;
  }

  &:nth-child(4) {
    background-position: -20% -30%;
    transform: rotate(60deg);
    animation-delay: 2.5s;
  }

  &:nth-child(5) {
    background-image:
      radial-gradient(2px 2px at 10px 100px, #eee, rgba(0, 0, 0, 0)),
      radial-gradient(2px 2px at 20px 10px, #fff, rgba(0, 0, 0, 0)),
      radial-gradient(3px 4px at 150px 40px, #ddd, rgba(0, 0, 0, 0));
    background-position: 80% 30%;
    animation-delay: 4s;
  }

  &:nth-child(6) {
    background-position: 50% 20%;
    animation-delay: 6s;
  }
}
@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
    transform: rotate(5deg);
    animation-timing-function: ease-in;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
    transform: scale(2.2);
  }
}
@media (prefers-reduced-motion) {
  .signup-stars {
    animation: none;
  }
}


.x-language{position:absolute;right:0;top:100px;z-index:6;margin-right: -4px;}
@media (max-width:1199.98px){
	.x-language{/*position:relative;top:unset;z-index:unset;display:flex*/}
}
.x-language .-link-wrapper{
	background-color:rgba(0,0,0,.5);border-top-left-radius:20px;border-bottom-left-radius:20px;display:flex;padding:.4rem .75rem;color:#fff;align-items: center;
}
@media (min-width:1200px){
	.x-language .-link-wrapper:hover{text-decoration:none;background-color:rgba(0,0,0,.4)}
}@media (max-width:1199.98px){
	.x-language .-link-wrapper{padding:5px;margin-right:.5rem}
}@media (max-width:575.98px){
	.x-language .-link-wrapper{margin-right:.25rem}
}
.x-language .-link-wrapper .-image-wrapper{
	align-items:center;display:flex
}
.x-language .-link-wrapper .-image-wrapper .-ic-right-star-rank{width:30px}
@media (max-width:370px){
	.x-language .-link-wrapper .-image-wrapper .-ic-right-star-rank{min-width:25px}
}
.x-language .-link-wrapper .-text-wrapper{
	line-height:16px;font-size:1.1rem;margin-left:.5rem;
	display: block;
}

</style>
