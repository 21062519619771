<template>
  <div>
    <div class="center" v-if="showLoading">
          <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
      </div>
    <div v-else>
      <headerSection></headerSection>
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
            <div class="x-promotion-index animation-background">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                <h1 class="-title" style="font-size:20px;color:#fbc844;">{{ $t('huekoy_page.title') }}</h1>
                                </div>
                            </div>
                            <p class="-description">{{ $t('huekoy_page.title2') }}</p>
                        </div>
                    </div>
                </div>
            <!-- 'height':'100vh'-->
              <div class="container">
                <div class="x-wrapper-right-container">
                      <div id="contact_gl" class="section" style="padding-top: -100px !important;width:100%">
                          <div style="position: absolute; top: 50px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie
                                          :options="defaultOptionsHue"
                                          :height="250"
                                          :width="250"
                                          v-on:animCreated="handleAnimationHue"
                                      />
                                  </div>
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie
                                          :options="defaultOptionsKoy"
                                          :height="250"
                                          :width="250"
                                          v-on:animCreated="handleAnimationKoy"
                                          />
                                  </div>
                                  
                          </div>
                          <div :style="{'position': 'absolute','top': '80px', 'align-items': 'center', 'justify-content': 'center', 'display': 'flex', 'left': '0', 'right': '0'}">
                              <img src="../assets/images/huekoy/main_00090.png" :style="{'display':showImage}">
                          </div>
                          <div :style="{'position': 'absolute','top': '200px', 'align-items': 'center', 'justify-content': 'center', 'display': 'flex', 'left': '0', 'right': '0','display':(showResult)?'block':'none'}">
                                  <div :style="{'position': 'absolute', 'top': '0px', 'align-items': 'center', 'justify-content': 'center', 'display': win, 'left': '0', 'right': '0'}">
                                      <!-- <lottie :options="defaultOptionsWin" :height="550" :width="550"  v-on:animCreated="handleAnimationWin" /> -->
                                      <img src="../assets/images/win.png">
                                  </div>
                                  <div :style="{'position': 'absolute', 'top': '0px', 'align-items': 'center', 'justify-content': 'center', 'display': lose, 'left': '0', 'right': '0'}">
                                      <!-- <lottie :options="defaultOptionsLose" :height="550" :width="550"  v-on:animCreated="handleAnimationLose" /> -->
                                      <img src="../assets/images/lose.png">
                                  </div>
                          </div>
                          <div style="position: absolute; bottom: 250px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                          <div style="display: flex; flex: 1; align-items: center; justify-content: center;">
                              <div style="cursor: pointer;">
                              <img
                                  src="../assets/images/huekoy/HKK_2_0.png"
                                  width="150"
                                  @click="select('hue')"
                              />
                              </div>
                              <div style="cursor: pointer;">
                              <img
                                  src="../assets/images/huekoy/HKK_3_0.png"
                                  width="150"
                                  @click="select('koy')"
                              />
                              </div>
                          </div>
                          </div>
                          <p class="glow">{{loadingtext}}</p>
                      </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <footerSection></footerSection>
      </div>
    </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
import router from './../router'
import Lottie from './lottie.vue'
//import * as animateSantaWalk from '../assets/shiba_loading4.json'
import * as animateSantaWalk from '../assets/images/halloween/ghost2.json'
import * as animationData1 from "../assets/images/huekoy/HUR.json";
import * as animationData2 from "../assets/images/huekoy/KOY.json";
import moment from 'moment-timezone'
const axios = require('axios').default
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
name: 'Gift',
components: {
  headerSection: () => import('./Header.vue'),
  footerSection: () => import('./Footer.vue'),
  lottie: Lottie
},
created () {
  const user = localStorage.getItem('userData')
  if (user) {
    const _user = JSON.parse(user)
    this.$store.dispatch('user/setUser', _user).then(() => {
      this.checkUserId()
    }).catch(err => { console.error(err) })
  } else {
    firebase.auth().signOut()
    localStorage.removeItem('userData')
    router.replace('/login')
  }
  const animateSplashScreen = animateSantaWalk.default
  this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  const animage = animationData1.default;
    const animage2 = animationData2.default;
    this.defaultOptionsHue = {
      animationData: animage,
      autoplay: false,
      loop: false
    };
    this.defaultOptionsKoy = {
      animationData: animage2,
      autoplay: false,
      loop: false
    };
},
computed: {
  user () {
    return this.$store.state.user.user
  }
},
mounted () {
  const width = screen.width
  if(width <= 480)
    this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
  else
    this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg')
  this.animSplashScreen.play()
  setTimeout(() => {
    this.showLoading = false
    this.animSplashScreen.stop()
  }, 3500)
  // this.getGames()
},
data () {
  return {
      win: "none",
      lose: "none",
      showResult: false,
      showImage: "block",
      logoLeft: '',
      logoRight: '',
      angle: 0,
      gameKey: 'spin',
      showLoading: true,
      pictureUrl: '',
      code: '',
      games_web: [],
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
          classes: 'fadeIn',
          duration: 5000,
          iteration: 'infinite'
      },
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,

      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext: '',
      credit: '',
      save: false,
      canvas: null,
      frame: null,
      wheels: null,
      sections: [],
      sectionspoint: [],
      colors: [],
      percent: [],
      backgroundImage: ''
  }
},
methods: {
  select(select) {
      if (this.canOpen) {
        this.$confirm({
          message: `ยืนยันการเลือก${
            select === "hue" ? "หัว" : "ก้อย"
          }?`,
          button: {
            yes: "OK",
            no: "Cancel"
          },
          callback: async confirm => {
            if (confirm) {
              this.canOpen = false;
              const com = Math.floor(Math.random() * 2);
              let select2 = "";
              if (com === 0) {
                select2 = "hue";
                this.showImage = "none";
                this.animKoy.hide();
                this.animHue.play();
              } else {
                select2 = "koy";
                this.showImage = "none";
                this.animKoy.play();
                this.animHue.hide();
              } 

              
              if (select2 === select) {
                console.log("win");
                axios({
                  method: 'post',
                  url: 'https://api.shiba888.bet/qspin/huekoy',
                  data: {
                      key: this.user.key,
                      code: this.user.code,
                      credit: 1
                  }
                });
                setTimeout(() => {
                  this.showResult = true;
                  this.win = "flex";
                  this.lose = "none";
                  setTimeout(() => {
                      this.$confirm(
                      {
                          message: this.$t('huekoy_page.success'),
                          button: {
                              yes: 'OK'
                          },
                          callback: async (confirm) => {
                              if (confirm) {
                                  this.$router.push('/')
                              }
                          }
                      })
                  }, 1000)
                }, 3000);
              } else{
                  axios({
                      method: 'post',
                      url: 'https://api.shiba888.bet/qspin/huekoy',
                      data: {
                          key: this.user.key,
                          code: this.user.code,
                          credit: 0
                      }
                  });
                  setTimeout(() => {
                  this.$confirm({
                    message: this.$t('huekoy_page.fail'),
                    button: {
                      yes: 'OK'
                    },
                    callback: confirm => {
                      if (confirm) {
                        router.replace('/')
                      }
                    }
                  })
                }, 2000)
              }
            }
          }
        });
      }
  },
  handleAnimationHue: function(anim) {
      this.animHue = anim;
  },
  handleAnimationKoy: function(anim) {
      this.animKoy = anim;
  },
  handleAnimationSplashScreen: function (anim) {
    this.animSplashScreen = anim
  },
  //   async getGames () {
  //     this.games_web = (await firebase.database().ref('/spin').once('value')).val()
  //   },
  async checkUserId () {
    await firebase.firestore().collection('spin').where('userkey','==',this.user.key).where('type','==','huekoy').where('createdate','>=',moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 00:00:00').get().then(async (snapshot) => {
      if (snapshot.size) {
        this.$confirm(
          {
            message: this.$t('huekoy_page.message'),
            button: {
              yes: 'OK'
            },
            callback: confirm => {
              if (confirm) {
                this.$router.push('/')
              }
            }
          }
        )
      } else {
        await firebase.firestore().collection('daily_deposit').where('createdate','>=',moment().tz("Asia/Bangkok").add(-2,'days').format("YYYY-MM-DD")).where('userkey','==',this.user.key).orderBy('createdate','desc').get().then(async (snapshot2) => {
          let i = 0;
          let cashback = 0;
          const checkcashback = 300;
          snapshot2.forEach((doc)=>{
              if (Number(doc.data().cashback) > 0) {
                  i++;
                  cashback += doc.data().cashback
              }
          });
          if (i !== 2) {
              setTimeout(() => {
                  this.$confirm({
                      message: this.$t('huekoy_page.message2'),
                      button: {
                          yes: 'OK'
                      },
                      callback: confirm => {
                          this.loadingtext = this.$t('huekoy_page.message2')
                      }
                  })
              }, 3000);
              
           }else if(cashback < checkcashback) {
              const less = checkcashback - Number(cashback)
              setTimeout(() => {
                  this.$confirm({
                      message: this.$t('huekoy_page.message3',{checkcashback: checkcashback}),
                      button: {
                          yes: 'OK'
                      },
                      callback: confirm => {
                          this.loadingtext = this.$t('huekoy_page.message4',{less: less})
                      }
                  })
              }, 3000);
          }else{
              this.canOpen = true
              this.loadingtext = this.$t('huekoy_page.message5')
          }
        })
      }
    })
  },
  checkCssBox (game) {
    if (!game.status) {
      return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
    }
  },
  checkCss (game) {
    if (!this.permission || !this.permission[game.key]) {
      return 'filter: grayscale(100%);'
    }
    // const index = _.findIndex(this.favorites, function(o) { return o == code; });
    // if (index>-1) {
    //   return '';
    // } else {
    //   return 'filter: grayscale(100%);';
    // }
  },
  goto (type) {
    if (type === 'home') {
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      this.$router.push('/').catch(() => {})
    } else if (type === 'website') {
      window.location = this.web.link
      // this.gotoline()
    } else if (type === 'contact') {
      window.location = this.web.link_line
      // this.gotoline()
    }
  }
}
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }

    to {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
}
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
    /*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
    background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
    background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
.spin {   padding: 0px;
          margin: 0px;
          overflow: hidden;
          height: 100%;
/*background: rgb(255,204,92,1);
background: radial-gradient(circle, rgba(255,204,92,1) 49%, rgba(34,193,195) 100%);*/
      }
 .p2 {
     letter-spacing: 0;
     text-shadow: 0 0 80px rgba(255, 255, 255, .5);
    /* Clip Background Image */
     background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
     -webkit-background-clip: text;
     background-clip: text;
    /* Animate Background Image */
     -webkit-text-fill-color: transparent;
     -webkit-animation: aitf 80s linear infinite;
    /* Activate hardware acceleration for smoother animations */
     -webkit-transform: translate3d(0, 0, 0);
     -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
     0% {
         background-position: 0% 50%;
    }
     100% {
         background-position: 100% 50%;
    }
}
</style>
