<template>
  <div>
    <headerSection></headerSection>

    <div id="main__content" class="">
      <div class="x-main-container">
        <div class="x-main-content">
            <div class="x-promotion-index" :style="{ 'background-image': `url(${backgroundImage})`,'background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                    <h1 class="-title" style="color:#000;">{{$t('promotion_title')}}</h1>
                                </div>
                            </div>
                            <p class="-description" style="color:#000;">{{$t('description_all_brands')}}</p>
                            <p class="-description" style="color:#000;">{{$t('description_deposit')}}</p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row -row-wrapper x-divine-gutter">
                        <div class="col-12 col-md-6 mt-3 -col-wrapper x-divine-gutter animated fadeInUp" style="padding-top: 10px;" data-animatable="fadeInUp" data-delay="100" v-for="(item,index) in banners" :key="index" @click="showPromotion(item)">
                            <a href="#0" class="x-promotion-list-item ">
                            <picture>
                                <source type="image/png" :srcset="getLocalizedImage(item)">
                                <img class="-cover-img img-fluid" :alt="item.name" style="border-radius:0px;" width="200" height="200"  :src="getLocalizedImage(item)">
                            </picture>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container mt-4">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="-sub-title" style="color:#000;"><a href="https://shiba888.bet">Shiba888</a>&nbsp;{{$t('footer_description')}}</h2>
                            <p class="-description">
                              {{$t('footer_description2')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>


          <footerSection></footerSection>
        </div>
      </div>

      <div class="x-right-sidebar-container"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  data () {
    return {
      backgroundImage:'',
    }
  },
  components: {
    headerSection: () => import("./Header.vue"),
    footerSection: () => import("./Footer.vue")
  },
  mounted () {
    const width = screen.width
    if(width <= 480)
      this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
    else
      this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg')
    this.$store.dispatch('user/getBanners').catch(err => { console.error(err) });
    this.scrollToTop()
  },
  computed: {
  	banners(){
        return this.$store.state.user.banners;
    },
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    showPromotion(item){
      if(item.detail){
        this.$store.commit('user/SET_PROMOTION', item)
      }
    },
    getLocalizedImage(item) {
      const currentLang = this.$i18n.locale;
      const imageField = `image_${currentLang}`;
      
      // ใช้รูปตามภาษา ถ้าไม่มีใช้รูปภาษาไทย ถ้าไม่มีอีกใช้รูปปกติ
      return item[imageField] || item.image_th || item.image;
    },
  }
}
</script>
