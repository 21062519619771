import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
//import 'firebase/messaging'
import 'firebase/auth'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/gl-styles.css'
import '@/assets/css/toggleTab.css'
import '@/assets/css/fns-css.css'
import '@/assets/css/font-awesome.min.css'
import '@/assets/css/login.css'
import '@/assets/css/util.css'
import '@/assets/css/main.css'
import '@/assets/css/style2.css'
import VModal from 'vue-js-modal'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSweetalert2 from 'vue-sweetalert2'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#FFD74C',
  cancelButtonColor: '#ff7674'
}
Vue.use(VueSweetalert2, options)
Vue.use(VModal)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
const configFirebase = {
  apiKey: 'AIzaSyAnA8eb37qLxrfA3IuXV3rzEqxpE16hIII',
  authDomain: 'shiba888.bet',
  databaseURL: 'https://shiba-88-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'shiba-88',
  storageBucket: 'shiba-88.appspot.com',
  messagingSenderId: '831612083564',
  appId: '1:831612083564:web:10f037fa3ecd62e959a749',
  //authDomain: 'shiba888.bet',
}
firebase.initializeApp(configFirebase)
// const messaging = firebase.messaging();
// messaging.getToken({ vapidKey: 'BKvTNYSBjXKDunvvExq8V6NghN_PKBS36ZjtTo7rS4e5SqQ4HO28WiJ9PQ1ZO5gOt2xhnRuORU8weja2IbdqvWc' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });
// messaging.onMessage(function(payload) {
//   console.log("Message received. ", payload);
//   // ...
// });
Vue.config.productionTip = false
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
